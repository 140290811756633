import React, { Component } from 'react';
import SideBar from '../components/SideBar';
import ThemeHeader from '../components/Header/ThemeHeader';
import { Link } from 'react-router-dom';

import './table.css';

export default class Doctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      search: '',
    };
  }
  componentDidMount() {
    const url = 'https://pacs.space:5000/getdoctorlist';
    const search = {
      search: this.state.search,
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(search),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then((res) => res.json())
      .catch((err) => alert(err))
      .then((res) => {
        // console.log(res);
        this.setState({ data: res.data });
      });
  }

  handleChange = () => {
    var value = event.target.value;
    var name = event.target.name;
    this.setState({ [name]: value });
    if (value === '') {
      event.preventDefault();
      const url = 'https://pacs.space:5000/getdoctorlist';
      const search = {
        search: value,
      };
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(search),
        headers: {
          'Content-Type': 'application/json',
          accesstoken: sessionStorage.getItem('token'),
        },
      })
        .then((res) => res.json())
        .catch((err) => alert(err))
        .then((res) => {
          // console.log(res);
          this.setState({ data: res.data });
        });
    }
  };

  handleStatus = (id, status, email) => {
    var index = event.target.getAttribute('data-key');
    const url = 'https://pacs.space:5000/updatedoctorstatus';
    const data = {
      id: id,
      status: status,
      email: email,
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        // console.log(this.state.data[index]);
        const { data } = this.state;
        var s = status === 0 ? 1 : 0;
        data[index].status = s;
        this.setState({
          data,
        });
      })
      .catch((error) => alert(error));
  };

  handleSearch = (event) => {
    event.preventDefault();
    const url = 'https://pacs.space:5000/getdoctorlist';
    const search = {
      search: this.state.search,
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(search),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then((res) => res.json())
      .catch((err) => alert(err))
      .then((res) => {
        // console.log(res);
        this.setState({ data: res.data });
      });
  };

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleSearch(e);
    }
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: 'white',
          maxHeight: '100vh',
        }}
      >
        <div>
          <ThemeHeader />
          <div className="container-fluid">
            <div className="row">
              <SideBar />
              <div
                style={{
                  marginLeft: '230px',
                  minHeight: 'calc(100vh - 69px)',
                  marginTop: '69px',
                  padding: '2% 2% 0 2%',
                }}
              >
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div style={{ fontSize: '23px' }}>
                    <span style={{ fontSize: '30px' }}>Doctor List</span>
                    <span>
                      <Link
                        to={{
                          pathname: '/adddoctordetails',
                        }}
                      >
                        <button
                          style={{
                            fontSize: '20px',
                            float: 'right',
                            margin: '2px',
                          }}
                        >
                          Add Doctor
                        </button>
                      </Link>
                    </span>
                    <div
                      className="input-group"
                      style={{ width: '250px', float: 'right', margin: '2px' }}
                    >
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="name"
                        placeholder="Search.."
                        onChange={this.handleChange}
                        onKeyDown={this._handleKeyDown}
                        name="search"
                        style={{ height: '36px' }}
                      />
                      <span
                        className="input-group-addon"
                        style={{ padding: '0px 5px', marginBottom: '2px' }}
                        onClick={this.handleSearch}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                  <div className="panel-body">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Hospital Name</th>
                          <th>Designation</th>
                          <th>Contact Number</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      {this.state.data && this.state.data !== '' ? (
                        <tbody>
                          {this.state.data.map((data, index) => {
                            return (
                              <tr key={data.id} className="fontcolor">
                                <td>{index + 1}</td>
                                <td style={{ color: 'black' }}>
                                  {data.fname} {data.lname}
                                </td>
                                <td>{data.email}</td>
                                <td>{data.hospitalname}</td>
                                <td>{data.designation}</td>
                                <td>{data.cno}</td>
                                <td>
                                  {data.status === 1
                                    ? 'Activate'
                                    : 'Deactivate'}
                                </td>
                                <td>
                                  <span>
                                    <Link
                                      to={{
                                        state: {
                                          data: data,
                                        },
                                        pathname: '/editdoctordetails',
                                      }}
                                    >
                                      <button>Edit</button>
                                    </Link>
                                  </span>
                                  <span>
                                    <button
                                      style={{ minWidth: '38%' }}
                                      data-key={index}
                                      onClick={() =>
                                        this.handleStatus(
                                          data.id,
                                          data.status,
                                          data.email
                                        )
                                      }
                                    >
                                      {data.status === 0
                                        ? 'Activate'
                                        : 'Deactivate'}
                                    </button>
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      ) : (
                        <div>
                          <h3>No Results</h3>
                        </div>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
