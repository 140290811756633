import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import OTPInput from 'otp-input-react';
export default class otp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: '',
      validate: false,
      email: '',
    };
  }
  componentDidMount() {
    console.log(this.props.location.state);
    this.setState({
      email: this.props.location.state.email,
    });
    console.log(this.state.email);
  }

  handleChange = otp => {
    const value = event.target.value;
    this.setState({ otp: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.otp.trim() !== '' || this.state.otp.length === '6') {
      const url = 'https://pacs.space:5000/verifyotp';
      // const url = 'http://localhost:8000/verifyotp';
      const data = {
        otp: this.state.otp,
        email: this.state.email,
      };
      console.log(data);
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(res => res.json())
        .catch(error =>
          toast.error(error, { position: toast.POSITION.TOP_CENTER })
        )
        .then(res => {
          // console.log(res);
          if (res.success === true) {
            toast.success(res.message, {
              position: toast.POSITION.TOP_CENTER,
              onClose: () => this.setState({ validate: true }),
            });
            // alert(res.message);
            // this.setState({ validate: true });
          } else {
            toast.error(res.message, { position: toast.POSITION.TOP_CENTER });
            // alert(res.message);
          }
        });
    } else {
      toast.success('otp must be of 6 digits', {
        position: toast.POSITION.TOP_CENTER,
      });
      // alert('otp must be of 6 digits');
    }
  };

  render() {
    if (this.state.validate === true) {
      return (
        <Redirect
          to={{
            pathname: '/recover_password',
            state: { email: this.state.email },
          }}
        />
      );
    }
    return (
      <div style={{ backgroundColor: 'white', height: '100vh' }}>
        <div>
          <div
            className="panel panel-default col-3 mx-auto"
            style={{ paddingTop: '10%' }}
          >
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label>Enter OTP</label>
                <div>
                  <input
                    type="number"
                    className="form-control"
                    name="password"
                    maxlength="6"
                    onChange={this.handleChange}
                    placeholder="Your password"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-success w-100"
                  style={{ marginTop: '25px' }}
                  //   disabled={otp.length < numInputs}
                >
                  verify OTP
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
