import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
// import { connect } from 'react-redux';
// import setCurrentUser from '../../../core/src/redux/reducers/auth.js';
import 'react-toastify/dist/ReactToastify.css';
// import store from '../store/index.js';

export default class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      validate: false,
      type: 'password',
      link: '/',
      loading: false,
    };
  }

  // componentDidMount() {
  //   const a = sessionStorage.getItem('auth');
  //   console.log(a);
  //   {
  //     session && this.setState({ link: a });
  //   }
  // }
  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name.trim !== '') {
      this.setState({ [name]: value });
    } else {
      toast.warn(`${name} cannot be empty`, {
        position: toast.POSITION.TOP_CENTER,
      });
      // alert(`${name} cannot be empty`);
    }
  };

  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === 'text' ? 'password' : 'text',
    }));

  pwdValidation = () => {
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
    if (reg.test(this.state.password)) {
      return true;
    } else {
      return false;
    }
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    event.preventDefault();
    const pwdValidation = this.pwdValidation();
    if (pwdValidation) {
      const url = 'https://pacs.space:5000/signin';
      const data = {
        email: this.state.email.toLowerCase(),
        password: this.state.password.trim(),
      };
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((res) => res.json())
        .catch(
          (error) =>
            toast.error('Something went Wrong', {
              position: toast.POSITION.TOP_CENTER,
            })
          // alert('Something went Wrong')
        )
        .then((res) => {
          if (res.success === true) {
            toast.success(res.message, {
              position: toast.POSITION.TOP_CENTER,
              onClose: () => this.setState({ validate: true }),
            });
            sessionStorage.setItem('token', res.token);
            sessionStorage.setItem('type', res.type);
            sessionStorage.setItem('email', res.email);
            sessionStorage.setItem('auth', JSON.stringify(res.auth));
            this.setState({ loading: false });
          } else {
            toast.error(res.message, { position: toast.POSITION.TOP_CENTER });
            // alert(res.message);
            this.setState({ loading: false });
          }
        });
    } else {
      toast.error(
        'Password will comprise of 1 digit, 1 special character and 1 upper case character',
        { position: toast.POSITION.TOP_CENTER }
      );
      this.setState({ loading: false });
      // alert(
      //   'Password will comprise of 1 digit, 1 special character and 1 upper case character'
      // );
    }
  };

  // handleredux = () => {
  //   this.props.setCurrentUser('hello');
  // };

  render() {
    // console.log(this.props.myname);
    if (this.state.validate === true) {
      return <Redirect to="/studylist" />;
    }
    // <ConnectedSignin user="hello" />;
    return (
      <div
        style={{
          backgroundColor: 'white',
          height: '100vh',
          backgroundImage: `url('https://southdenvermoms.com/wp-content/uploads/sites/15/2019/10/Stethascope-Image.jpg')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div>
          <div
            className="panel panel-default col-3 "
            style={{ paddingTop: '10%', marginLeft: '65%' }}
          >
            <img
              src="https://lh3.googleusercontent.com/-CmMZF4BhqvM/Xny0EijzbkI/AAAAAAAAF44/RL70Kp3o5koIqm9HbwkqOR_t9BGpq47zACK8BGAsYHg/s0/2020-03-26.jpg"
              style={{
                width: 'inherit',
                float: 'left',
                margin: '0 5px 25px 0',
              }}
              alt=""
            />
            {/* <button
              onClick={() => {
                this.props.changename('suresh');
              }}
            >
              hello
            </button> */}
            <div className="panel-body">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label style={{ color: 'black' }}>Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    onChange={this.handleChange}
                    placeholder="Your email address"
                    required
                  />
                </div>
                <div className="form-group">
                  <label style={{ color: 'black' }}>Password</label>
                  <div className="input-group">
                    <input
                      type={this.state.type}
                      className="form-control"
                      name="password"
                      onChange={this.handleChange}
                      placeholder="Your password"
                      required
                    />
                    <span
                      className="input-group-addon"
                      style={{ width: '40px' }}
                      onClick={this.handleClick}
                    >
                      {this.state.type === 'text' ? (
                        <i
                          className="fa fa-eye"
                          aria-hidden="true"
                          style={{ padding: '11px', backgroundColor: 'white' }}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye-slash"
                          aria-hidden="true"
                          style={{ padding: '11px', backgroundColor: 'white' }}
                        ></i>
                      )}
                    </span>
                  </div>
                </div>

                <div className="form-group">
                  <Link
                    to={{
                      pathname: '/emailotp',
                    }}
                  >
                    <div style={{ float: 'right' }}>Forgot password?</div>
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-success w-100"
                    style={{ marginTop: '25px' }}
                    disabled={this.state.loading}
                  >
                    {this.state.loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: '5px' }}
                      />
                    )}
                    {this.state.loading && <span>Logging</span>}
                    {!this.state.loading && <span>Login</span>}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = state => state.oidc;

// export function setCurrentUser(user) {
//   return {
//     type: SET_CURRENT_USER,
//     user,
//   };
// }

// const mapStateToProps = state => {
//   console.log(state);
//   return {
//     myname: state.setCurrentUser.name,
//   };
// };

// const mapDispatchToProps = dispatch => {
//   return {
//     changename: name => {
//       dispatch({ type: 'SET_CURRENT_USER', payload: name, user: true });
//     },
//   };
// };

// store.dispatch({ type: 'SET_CURRENT_USER', setCurrentUser });

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Signin);
