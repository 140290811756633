import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import { Redirect } from 'react-router-dom';

export default class IdleTimerContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      redirectshared: false,
      share: '',
    };
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }
  // const [redirect, setRedirect] = useState(false);
  // const [redirectshared, setRedirectshared] = useState(false);
  // const [share, setshare] = useState('');
  // const idleTimerRef = useRef(null);

  // const onIdle = () => {
  //   alert('user is idle');
  //   if ('patient' === sessionStorage.getItem('type')) {
  //     var url = '/shared/' + sessionStorage.getItem('sharedurl');
  //     setshare(url);
  //     sessionStorage.removeItem('token');
  //     sessionStorage.removeItem('type');
  //     setRedirectshared(true);
  //   } else {
  //     sessionStorage.removeItem('token');
  //     sessionStorage.removeItem('type');
  //     setRedirect(true);
  //   }
  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/" />;
    } else if (this.state.redirectshared === true) {
      return <Redirect to={this.state.share} />;
    } else {
      return (
        <div>
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={1000 * 60 * 10}
          />
          {/* your app here */}
        </div>
      );
    }
  }

  _onAction(e) {
    console.log('user did something', e);
  }

  _onActive(e) {
    console.log('user is active', e);
    console.log('time remaining', this.idleTimer.getRemainingTime());
  }

  _onIdle(e) {
    if ('patient' === sessionStorage.getItem('type')) {
      var url = '/shared/' + sessionStorage.getItem('sharedurl');
      setshare(url);
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('type');
      this.setState({ redirect: true });
    } else {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('type');
      this.setState({ redirectshared: true });
    }
  }
}
