import React, { Component } from 'react';
import SideBar from '../components/SideBar';
import ThemeHeader from '../components/Header/ThemeHeader';
import { Link } from 'react-router-dom';
import './table.css';
export default class Branching extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      search: '',
      Institution: [],
      modality: [],
      selectinstitution: '',
      selectmodality: '',
      syncstatus: false,
    };
  }
  // componentDidMount() {
  //   // const url = 'https://pacs.space:5000/getpatientlist';
  //   const url = 'http://localhost:8000/getpatientlist';
  //   fetch(url, {
  //     headers: {
  //       accesstoken: sessionStorage.getItem('token'),
  //     },
  //   })
  //     .then(response => response.json())
  //     .catch(error => alert(error))
  //     .then(response => this.setState({ data: response.data }));
  // }
  componentDidMount() {
    const url = 'https://pacs.space:5000/remote_modality_branching';
    const obj = {
      institute: '',
      modality: '',
    };
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .catch((err) => alert(err))
      .then((res) => {
        console.log(res);
        this.setState({ data: res.data });
      });
    fetch('https://pacs.space:5000/get_institute_name', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then((res) => res.json())
      .catch((err) => alert(err))
      .then((res) => {
        console.log(res);
        this.setState({ Institution: res.data });
      });
    fetch('https://pacs.space:5000/get_modality_name', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .catch((error) => alert(error))
      .then((response) => {
        console.log(response.data);
        this.setState({ modality: response.data });
      });
  }

  handleSync = () => {
    this.setState({ syncstatus: true });
    fetch('https://pacs.space:5000/remote_aet', {
      method: 'POST',
      headers: {
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .catch((error) => alert(error))
      .then((response) => {
        console.log(response);
        this.setState({ syncstatus: false });
      });
  };
  handleins = (event) => {
    this.setState({ selectinstitution: event.target.value });
    const obj = {
      institute: event.target.value,
      modality: this.state.selectmodality,
    };
    console.log(obj);
    const url = 'https://pacs.space:5000/remote_modality_branching';
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then((res) => res.json())
      .catch((err) => alert(err))
      .then((res) => {
        console.log(res);
        this.setState({ data: res.data });
      });
  };
  handlemod = (event) => {
    const obj = {
      institute: this.state.selectinstitution,
      modality: event.target.value,
    };
    console.log(obj);
    const url = 'https://pacs.space:5000/remote_modality_branching';
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then((res) => res.json())
      .catch((err) => alert(err))
      .then((res) => {
        console.log(res);
        this.setState({ data: res.data });
      });
    this.setState({ selectmodality: event.target.value });
  };

  render() {
    return (
      <div style={{ backgroundColor: 'white', maxHeight: '100vh' }}>
        <ThemeHeader />
        <div
          className="container-fluid"
          style={{ backgroundColor: 'white', paddingBottom: '1px' }}
        >
          <div className="row">
            <SideBar />
            <div
              style={{
                marginLeft: '230px',
                minHeight: 'calc(100vh - 69px)',
                marginTop: '69px',
                padding: '2% 2% 0 2%',
              }}
            >
              <div>
                {/* <h1>Coming Soon</h1> */}
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div>
                    <span style={{ fontSize: '30px' }}>Branch List</span>
                    {/* <span>
                      <button
                        className="btn btn-success"
                        style={{
                          float: 'right',
                          margin: '5px',
                        }}
                        onClick={this.handleSync}
                      ></button>
                    </span> */}
                    <span>
                      <button
                        className="btn btn-success"
                        style={{
                          float: 'right',
                          margin: '5px',
                        }}
                        onClick={this.handleSync}
                        disabled={this.state.syncstatus}
                      >
                        {this.state.syncstatus && (
                          <i
                            className="fa fa-refresh fa-spin"
                            style={{ marginRight: '5px' }}
                          />
                        )}
                        {this.state.syncstatus && <span>Syncing</span>}
                        {!this.state.syncstatus && <span>Sync </span>}
                      </button>
                    </span>
                    <span style={{ float: 'right' }}>
                      <select
                        value={this.state.selectinstitution}
                        onChange={this.handleins}
                        style={{
                          height: '38px',
                          borderRadius: '5px',
                          margin: '5px',
                          backgroundColor: '#16A085',
                          color: 'white',
                        }}
                      >
                        <option selected value="">
                          Select Institution Name
                        </option>
                        {this.state.Institution &&
                          this.state.Institution.map((data) => {
                            if (
                              data.institutionname != ''
                              //   data.institutionname != 'No Institution'
                            ) {
                              return (
                                <option value={data.institutionname}>
                                  {data.institutionname}
                                </option>
                              );
                            }
                          })}
                      </select>
                    </span>
                    <span style={{ float: 'right' }}>
                      <div>
                        <select
                          value={this.state.selectmodality}
                          onChange={this.handlemod}
                          style={{
                            height: '38px',
                            borderRadius: '5px',
                            margin: '5px',
                            backgroundColor: '#16A085',
                            color: 'white',
                          }}
                        >
                          <option selected value="">
                            Select Aet Title
                          </option>
                          {this.state.modality &&
                            this.state.modality.map((data) => {
                              return (
                                <option value={data.remoteaet}>
                                  {data.remoteaet}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </span>
                  </div>
                  <div className="panel-body">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Patient Name</th>
                          <th>Accesion Number</th>
                          <th>Modality</th>
                          <th>AET</th>
                          <th>Institution</th>
                          <th>Manufacturer</th>
                          <th>Study Description</th>
                          <th>Station Name</th>
                          <th>Received Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data && this.state.data !== '' ? (
                          this.state.data.map((data, index) => {
                            return (
                              <tr key={data.id} className="fontcolor">
                                <td>{index + 1}</td>
                                <td>{data.patientName}</td>
                                <td>{data.accessionNumber}</td>

                                <td>{data.modalities}</td>
                                <td>{data.remoteaet}</td>
                                <td>{data.institutionname}</td>
                                <td>{data.manufacturername}</td>
                                <td>{data.studyDescription}</td>
                                <td>{data.stationname}</td>
                                <td>
                                  {data.receivedon.substring(6, 8)}/
                                  {data.receivedon.substring(4, 6)}/
                                  {data.receivedon.substring(0, 4)}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <div>
                            <h3>No Result Found</h3>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
