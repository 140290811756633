import React, { Component } from 'react';
import ThemeHeader from '../components/Header/ThemeHeader';
import SideBar from '../components/SideBar';
import EditModalityDetails from '../components/Forms/EditModalityDetails.js';

export default class EditModality extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      ip: null,
    };
  }
  componentDidMount() {
    console.log(this.props.location.state);
    var arr = this.props.location.state.data.ipaddress.split('.');
    this.setState({ data: this.props.location.state.data, ip: arr });
  }
  render() {
    return (
      <div style={{ backgroundColor: 'white', maxHeight: '100vh' }}>
        <div>
          <ThemeHeader />
          <div className="container-fluid">
            <div className="row" style={{ backgroundColor: 'white' }}>
              <SideBar />

              <div
                className="col-9"
                style={{
                  marginLeft: '230px',
                  minHeight: 'calc(100vh - 69px)',
                  marginTop: '69px',
                  padding: '2% 15% 0 25%',
                }}
              >
                {this.state.data && this.state.ip && (
                  <EditModalityDetails
                    data={this.state.data}
                    ip={this.state.ip}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
