import React, { Component } from 'react';
import ThemeHeader from '../components/Header/ThemeHeader';
import SideBar from '../components/SideBar';
import AddModalityDetails from '../components/Forms/AddModalityDetails.js';
// import data from '/home/anuj/Desktop/ohif/medi-surf-2.0/Viewers/.docker/Nginx-Orthanc/config/orthanc.json';
// import writeJsonFile from 'write-json-file';

export default class AddModality extends Component {
  // componentDidMount() {
  //   console.log(data);
  //   var obj = data;
  //   this.setState({ data: obj }, this.check);
  // }

  // handle = async () => {
  //   alert('aaya');
  //   const file =
  //     '/home/anuj/Desktop/ohif/medi-surf-2.0/Viewers/.docker/Nginx-Orthanc/config/foo.json';
  //   const obj = { name: 'JP' };

  //   jsonfile.writeFile(file, obj, function(err) {
  //     if (err) console.error(err);
  //   });
  // };

  // check = () => {
  //   console.log(this.state.data);
  // };

  render() {
    return (
      <div style={{ backgroundColor: 'white', maxHeight: '100vh' }}>
        <div>
          <ThemeHeader />
          <div className="container-fluid">
            <div className="row" style={{ backgroundColor: 'white' }}>
              <SideBar />

              <div
                className="col-9"
                style={{
                  marginLeft: '230px',
                  minHeight: 'calc(100vh - 69px)',
                  marginTop: '69px',
                  padding: '2% 15% 0 25%',
                }}
              >
                <AddModalityDetails />

                {/* <div style={{ backgroundColor: 'blue' }}>
                  {this.state.data && this.state.data.OrthancPeers.Url[0]}
                  hello
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
