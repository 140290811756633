import React, { Component } from 'react';
import ThemeHeader from '../components/Header/ThemeHeader';
import SideBar from '../components/SideBar';
import { Redirect, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import AddModalityDetails from '../components/Forms/AddModalityDetails.js';

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      validate: false,
      type: 'password',
      error: false,
      match: false,
    };
  }

  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === 'text' ? 'password' : 'text',
    }));

  pwdValidation = () => {
    // console.log('hello');
    if (this.state.password.trim() !== '') {
      return true;
    } else {
      return false;
    }
  };

  handleChangePassword = () => {
    const name = event.target.name;
    const value = event.target.value;
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
    if (reg.test(value)) {
      this.setState({ error: true, password: value });
    } else {
      this.setState({ error: false });
    }
  };

  handleChangeConfirmPassword = () => {
    const name = event.target.name;
    const value = event.target.value;
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
    if (reg.test(value) && value === this.state.password) {
      this.setState({ match: true });
    } else {
      this.setState({ match: false });
    }
  };

  handleSubmit = () => {
    event.preventDefault();
    const pwdValidation = this.pwdValidation();
    if (pwdValidation) {
      const url = 'https://pacs.space:5000/recoverpassword';
      const data = {
        password: this.state.password.trim(),
      };
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          accesstoken: sessionStorage.getItem('token'),
        },
      })
        .then((res) => res.json())
        .catch(
          (error) =>
            toast.error('Something went wrong try again', {
              position: toast.POSITION.TOP_CENTER,
            })
          // alert('Something went wrong try again')
        )
        .then((res) => {
          if (res) {
            toast.success(res.message, {
              position: toast.POSITION.TOP_CENTER,
              onClose: () => this.setState({ validate: true }),
            });
            // alert(res.message);
          } else {
            toast.error(res.message, { position: toast.POSITION.TOP_CENTER });
            // alert(res.message);
          }
        });
    } else {
      toast.warn(
        'Password will comprise of 1 digit, 1 special character and 1 upper case character',
        { position: toast.POSITION.TOP_CENTER }
      );
      // alert(
      //   'Password will comprise of 1 digit, 1 special character and 1 upper case character'
      // );
    }
  };
  render() {
    if (this.state.validate === true) {
      return <Redirect to="/" />;
    }
    return (
      <div style={{ backgroundColor: 'white', maxHeight: '100vh' }}>
        <div>
          <ThemeHeader />
          <div className="container-fluid">
            <div className="row" style={{ backgroundColor: 'white' }}>
              <SideBar />

              <div
                className="col-9"
                style={{
                  marginLeft: '18%',
                  minHeight: 'calc(100vh - 69px)',
                  marginTop: '69px',
                  padding: '2% 15% 0 25%',
                }}
              >
                <form onSubmit={this.handleSubmit}>
                  <div
                    className="panel panel-default col-8"
                    style={{ paddingTop: '10%' }}
                  >
                    <h1 style={{ fontSize: '2vw', padding: 'inherit' }}>
                      Create New Password
                    </h1>
                    <div className="form-group">
                      <label>New Password</label>
                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          onChange={this.handleChangePassword}
                          placeholder="New password"
                          required
                        />
                      </div>
                      <div>
                        {this.state.error === false ? (
                          <p style={{ fontSize: 'small' }}>
                            (Password should contain upper case, digit and 1
                            special character)
                          </p>
                        ) : (
                          <p style={{ fontSize: 'small' }}>Perfect</p>
                        )}
                      </div>
                      <label>Confirm Password</label>
                      <div className="input-group">
                        <input
                          type={this.state.type}
                          className="form-control"
                          name="confirm
                password"
                          onChange={this.handleChangeConfirmPassword}
                          placeholder="Confirm password"
                          required
                        />
                        <span
                          className="input-group-addon"
                          style={{ width: '40px' }}
                          onClick={this.handleClick}
                        >
                          {this.state.type === 'text' ? (
                            <i
                              className="fa fa-eye"
                              aria-hidden="true"
                              style={{ padding: '10px' }}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-eye-slash"
                              aria-hidden="true"
                              style={{ padding: '10px' }}
                            ></i>
                          )}
                        </span>
                      </div>
                      <div>
                        {this.state.match === true ? (
                          <p style={{ fontSize: 'small' }}>Confirmed</p>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-success w-100"
                      style={{ marginTop: '25px' }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
