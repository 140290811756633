import React, { Component } from 'react';
import axios, * as others from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom';
import { Prompt } from 'react-router';

toast.configure();

export default class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: [],
      validate: false,
      loading: false,
    };
  }

  handleSubmit = () => {
    event.preventDefault();
    this.setState({ loading: true });
    var count = 0;

    if (this.state.file === null) {
      // alert('please choose file ');
      toast.warn('please choose file', { position: toast.POSITION.TOP_CENTER });
    } else {
      for (let index = 0; index < this.state.file.length; index++) {
        if (!this.state.file[index].name.match(/(\.dcm)$/i)) {
          toast.warn('Please upload correct file', {
            position: toast.POSITION.TOP_CENTER,
            onClose: () => this.setState({ loading: false }),
          });
        } else if (this.state.file[index].size > 999999) {
          toast.warn('Please upload file within 1 MB', {
            position: toast.POSITION.TOP_CENTER,
            onClose: () => this.setState({ loading: false }),
          });
        } else {
          const element = this.state.file[index];
          let formdata = new FormData();
          formdata.append('binary-data', element);
          console.log(this.state.file[0]);

          // fetch('https://pacs.space:8082/instances', {
          //   method: 'POST',
          //   mode: 'no-cors',
          //   body: formdata,
          //   headers: {
          //     'Content-Type': 'application/json',
          //     // Accept: 'application/dicom+json',
          //     'Access-Control-Allow-Origin': '*',
          //   },
          // })
          axios({
            method: 'post',
            url: 'https://pacs.space:8082/instances',
            data: formdata,
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then((res) => {
              count++;
              if (count == this.state.file.length) {
                toast.success(res.data.Status, {
                  position: toast.POSITION.TOP_CENTER,
                  onClose: () => this.setState({ loading: false }),
                });
              }
            })
            .catch((error) => {
              // alert(error);
              toast.error(error, { position: toast.POSITION.TOP_CENTER });
            });
        }

        // .then(res => {
        //   console.log(res);
        //   this.setState({ loading: true });
        //   setTimeout(() => {
        //     this.setState({ loading: false });
        //     if (res) {
        //       this.notify();
        //     }
        //   }, 2000);
        // });
      }
    }
  };
  notify = () => {
    toast.success('Exam uploaded successfully', {
      position: toast.POSITION.TOP_CENTER,
      onClose: () => this.setState({ validate: true }),
    });
    // alert('Exam uploaded successfully');
    // this.setState({ validate: true });
  };

  handleImageChange = (e) => {
    // if (e.target.file.name.match(/(\.dcm)$/i)) {
    const file = Array.from(event.target.files);
    this.setState({ file });
    // } else {
    // alert('please upload correct file');
    // toast.warn('Please upload correct file', {
    //   position: toast.POSITION.TOP_CENTER,
    // });
    // e.target.value = '';
    // }

    // () => {
    //   this.handleSubmit();
    // }
  };

  render() {
    if (this.state.validate === true) {
      return <Redirect to="/studylist" />;
    }
    return (
      <div className="panel panel-default">
        <Prompt
          when={this.state.loading == true}
          message="Images are uploading,Are you sure you want to leave.If yes then images will be uploaded in background"
        />
        <h2 style={{ padding: '30px' }}>Upload Dicom Image</h2>

        <div className="panel-body" style={{ paddingBottom: '15%' }}>
          <form onSubmit={this.handleSubmit}>
            <div>
              <input
                type="file"
                name="file"
                onChange={this.handleImageChange}
                multiple
              />
            </div>
            <button
              type="submit"
              className="btn btn-success w-100"
              style={{ marginTop: '25px' }}
              disabled={this.state.loading}
            >
              {this.state.loading && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: '5px' }}
                />
              )}
              {this.state.loading && <span>Uploading Data</span>}
              {!this.state.loading && <span>Upload </span>}
            </button>
          </form>
        </div>
      </div>
    );
  }
}
