import React, { Component } from 'react';

import { Redirect, Link } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const options = [
  { value: '/doctors', label: 'Doctor' },
  { value: '/cms', label: 'Settings' },
  // { value: '/studylist', label: 'Study List' },
  { value: '/upload_dicom_image', label: 'Upload Exam' },
  { value: '/subadmin_listing', label: 'Power User' },
];
const animatedComponents = makeAnimated();

export default class AddDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      email: null,
      lname: null,
      fname: null,
      redirect: false,
      selectedOption: [],
      authfailed: false,
    };
  }

  onChange = (date) => this.setState({ date });

  apicall = () => {
    const url = 'https://pacs.space:5000/addsubuser';
    // const url = 'http://159.89.173.21:8000/addDetails';
    const data = {
      lname: this.state.lname.trim(),
      fname: this.state.fname.trim(),
      email: this.state.email.toLowerCase(),
      date: this.state.date,
      selectedOption: JSON.stringify(this.state.selectedOption),
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then((res) => res.json())
      .catch((error) => alert('Something went wrong try again'))
      .then((res) => {
        if (res.message === 'Auth Failed') {
          this.setState({ authfailed: true });
        } else {
          if (res.success === true) {
            alert('Succesfully added');
            this.setState({ redirect: true });
          }
          if (res.success === false) {
            alert(res.message);
          }
        }
      });
  };

  validation = () => {
    event.preventDefault();
    const { fname, lname, date } = this.state;
    if (fname.trim() === '') {
      alert('First Name cannot be empty');
    } else if (lname.trim() === '') {
      alert('Last Name cannot be empty');
    } else if (date === null) {
      alert('Date cannot be empty');
    } else {
      this.apicall();
    }
  };
  handleList = (options) => {
    this.setState({ selectedOption: options });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({ [name]: value });
  };

  render() {
    if (this.state.authfailed && this.state.authfailed === true) {
      return (
        <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
          UnAuthorised Access
        </div>
      );
    } else {
      if (this.state.redirect === true) {
        return <Redirect to="/subadmin_listing" />;
      }
      return (
        <div className="panel panel-default">
          <div className="panel-heading" style={{ fontSize: '20px' }}>
            Add Power User
          </div>
          <div className="panel-body">
            <form id="form_basic_validation" onSubmit={this.validation}>
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="fname"
                  placeholder="First Name"
                  onChange={this.handleChange}
                  name="fname"
                  required
                />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="lname"
                  placeholder="Last Name"
                  onChange={this.handleChange}
                  name="lname"
                  required
                />
              </div>
              <div className="form-group">
                <label>Email Address</label>
                <input
                  type="email"
                  className="form-control form-control-sm"
                  id="email"
                  placeholder="Email"
                  onChange={this.handleChange}
                  name="email"
                  required
                />
              </div>
              <div className="form-group">
                <label>Access to Power User</label>
                <Select
                  onChange={this.handleList}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  defaultValue={options[0].value}
                  isMulti
                  options={options}
                />
              </div>
              <div className="form-group">
                <span>
                  <button
                    type="submit"
                    className="btn btn-sm btn-success pull-right"
                  >
                    Submit
                  </button>
                </span>
                <span>
                  <Link
                    to={{
                      pathname: '/subadmin_listing',
                    }}
                  >
                    <button
                      type="submit"
                      className="btn btn-sm btn-success pull-right mx-1"
                    >
                      Cancel
                    </button>
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }
  }
}
