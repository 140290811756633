import React, { Component } from 'react';

import { Redirect, Link } from 'react-router-dom';

export default class EditModalityDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      data: null,
      ip: [],
      ip1: '',
      ip2: '',
      ip3: '',
      ip0: '',
      redirect: false,
      authfailed: false,
    };
  }

  componentDidMount() {
    // console.log('hello');
    this.setState({
      data: this.props.data && this.props.data,
      ip: this.props.ip && this.props.ip,
    });
    // console.log(this.props.data);
  }

  // onChange = date => this.setState({ date });

  apicall = () => {
    // const url = 'http://159.89.173.21:8000/addsubuser';
    const url = 'https://pacs.space:5000/editmodality';
    const data = {
      dicomserver: this.state.data.ds,
      aet: this.state.data.aet,
      ipaddress: this.state.ip.join('.'),
      port: this.state.data.port,
      id: this.state.data.id,
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then(res => res.json())
      .catch(error => alert('Something went wrong try again'))
      .then(res => {
        if (res.message === 'Auth Failed') {
          this.setState({ authfailed: true });
        } else {
          if (res.success === true) {
            alert('Succesfully added');
            this.setState({ redirect: true });
          }
          if (res.success === false) {
            alert(res.message);
          }
        }
      });
  };

  validation = () => {
    event.preventDefault();
    this.apicall();

    // const { dicomserver } = this.state.data;
    // if (dicomserver.trim() === '') {
    //   alert('dicom server cannot be empty');
    // }
    // // else if (lname.trim() === '') {
    // //   alert('Last Name cannot be empty');
    // // } else if (date === null) {
    // //   alert('Date cannot be empty');
    // // }
    // else {

    // }
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    let newArr = {
      ...this.state.data,
      [name]: value,
    };
    this.setState({ data: newArr });
  };

  handleip = event => {
    const value = event.target.value;
    var index = event.target.getAttribute('data-key');
    console.log(index);
    // let arr = this.state.ip.splice(index, 1, value);
    // console.log(arr);
    // this.setState({ ip: arr });

    const arr = [...this.state.ip];
    arr[index] = value;
    this.setState({ ip: arr });
  };

  render() {
    if (this.state.authfailed && this.state.authfailed === true) {
      return (
        <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
          UnAuthorised Access
        </div>
      );
    } else {
      if (this.state.redirect === true) {
        return <Redirect to="/cms" />;
      }
      return (
        <div className="panel panel-default">
          <div className="panel-heading" style={{ fontSize: '20px' }}>
            Edit Modality
          </div>
          <div className="panel-body">
            <form id="form-horizontal" onSubmit={this.validation}>
              <div className="form-group">
                <label>Dicom Server</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="dicomserver"
                  value={this.state.data && this.state.data.ds}
                  onChange={this.handleChange}
                  name="ds"
                  disabled
                />
              </div>
              <div className="form-group">
                <label>AET </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="aet"
                  placeholder=""
                  onChange={this.handleChange}
                  value={this.state.data && this.state.data.aet}
                  name="aet"
                  required
                />
              </div>
              <div className="form-group">
                <label>IP Address</label>
                <div className="form-group row">
                  {this.state.ip &&
                    this.state.ip.map((value, index) => {
                      return (
                        <div className="col-sm-3">
                          <input
                            key={index}
                            type="number"
                            className="form-control form-control-sm"
                            onChange={this.handleip}
                            value={value}
                            data-key={index}
                            required
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
              {/* <div className="col-sm-3">
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    id="ip2"
                    placeholder=""
                    onChange={this.handleChange}
                    name="ip2"
                    required
                  />
                </div>
                <div className="col-sm-3">
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    id="ip3"
                    placeholder=""
                    onChange={this.handleChange}
                    name="ip3"
                    required
                  />
                </div>
                <div className="col-sm-3">
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    id="ip4"
                    placeholder=""
                    onChange={this.handleChange}
                    name="ip4"
                    required
                  />
                </div> */}

              <div className="form-group">
                <label> Port</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="port"
                  onChange={this.handleChange}
                  value={this.state.data && this.state.data.port}
                  name="port"
                  required
                />
              </div>
              <div className="form-group">
                <span>
                  <button
                    type="submit"
                    className="btn btn-sm btn-success pull-right"
                  >
                    Submit
                  </button>
                </span>
                <span>
                  <Link
                    to={{
                      pathname: '/cms',
                    }}
                  >
                    <button
                      type="submit"
                      className="btn btn-sm btn-success pull-right mx-1"
                    >
                      Cancel
                    </button>
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }
  }
}
