import React from 'react';
import './createUser.css';
import SideBar from '../components/SideBar';
import AddDetails from '../components/Forms/AddDetails';
import ThemeHeader from '../components/Header/ThemeHeader';

class CreateUser extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     date: new Date(),
  //     email: null,
  //     cno: null,
  //     lname: null,
  //     fname: null,
  //     redirect: false,
  //     location: null,
  //   };
  // }

  // setlocation = Location => {
  //   this.setState({
  //     location: Location,
  //   });
  // };

  // getcurrentlocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(position => {
  //       console.log(position);
  //       fetch(
  //         'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
  //           position.coords.latitude +
  //           ',' +
  //           position.coords.longitude +
  //           '&key=AIzaSyCIeyrzeVetCiVKGvqLtNl4ocP5zX_E1jo'
  //       )
  //         .then(res => res.json())
  //         .catch(error => alert(error))
  //         .then(res => {
  //           if (res) {
  //             Location = res.results[0].formatted_address;
  //             this.setlocation(Location);
  //           }
  //         });
  //     });
  //   }
  // };
  // onChange = date => this.setState({ date });

  // handleValidation = () => {
  //   let formIsValid = true;
  //   if (this.state.lname === '' || this.state.lname === null) {
  //     console.log('hello');
  //     formIsValid = false;
  //   }
  //   return formIsValid;
  // };

  // apicall = () => {
  //   const url = 'http://localhost:8000/addDetails';
  //   const data = {
  //     lname: this.state.lname.trim(),
  //     fname: this.state.fname.trim(),
  //     email: this.state.email,
  //     cno: this.state.cno.trim(),
  //     dob: this.state.date,
  //     location: this.state.location,
  //   };
  //   fetch(url, {
  //     method: 'POST',
  //     body: JSON.stringify(data),
  //     headers: { 'Content-Type': 'application/json' },
  //   })
  //     .then(res => res.json())
  //     .catch(error => alert('Something went wrong try again'))
  //     .then(res => {
  //       if (res.success === true) {
  //         alert('Succesfully added');
  //         this.setState({ redirect: true });
  //       }
  //       if (res.success === false) {
  //         alert(res.message);
  //       }
  //     });
  // };

  // validation = () => {
  //   event.preventDefault();
  //   const { fname, lname, cno, date, location } = this.state;
  //   if (
  //     fname.trim() === '' ||
  //     lname.trim() === '' ||
  //     cno.trim() === '' ||
  //     location.trim() === '' ||
  //     date === null
  //   ) {
  //     alert('Fields cannot be empty');
  //   } else if (cno.length !== 10) {
  //     alert('contact number should be of 10 digits');
  //   } else {
  //     this.apicall();
  //   }
  // };

  // handleChange = event => {
  //   const name = event.target.name;
  //   const value = event.target.value;

  //   this.setState({ [name]: value });
  // };

  // handleContact = event => {
  //   const name = event.target.name;
  //   const value = event.target.value;
  //   this.setState({ [name]: value });
  // };

  render() {
    return (
      <div style={{ backgroundColor: 'white', height: '100vh' }}>
        <div
        // style={{
        //   width: '80%',
        //   backgroundColor: 'lightgrey',
        //   paddingTop: '5%',
        // }}
        >
          <ThemeHeader />
          <div className="container-fluid">
            <div className="row" style={{ backgroundColor: 'white' }}>
              <SideBar />
              <div
                className="col-9"
                style={{
                  marginLeft: '230px',
                  minHeight: 'calc(100vh - 69px)',
                  marginTop: '69px',
                  padding: '2% 15% 0 25%',
                }}
              >
                <AddDetails />
              </div>
            </div>
            {/* <div
          className="justify-content-center"
          style={{
            marginLeft: '20%',
            backgroundColor: '#DCDCDC',
            padding: '20px',
          }}
        >
          Add Patient's Details
        </div>
        <form
          className="justify-content-center"
          style={{
            marginLeft: '20%',
            backgroundColor: 'white',
            padding: '20px',
          }}
          onSubmit={this.validation}
        >
          <div className="form-group row">
            <label
              forname="colFormLabelSm"
              className="col-sm-2 col-form-label col-form-label-sm"
            >
              First Name
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="fname"
                placeholder="First Name"
                onChange={this.handleChange}
                name="fname"
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              forname="colFormLabelSm"
              className="col-sm-2 col-form-label col-form-label-sm"
            >
              Last Name
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="lname"
                placeholder="Last Name"
                onChange={this.handleChange}
                name="lname"
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              forname="colFormLabelSm"
              className="col-sm-2 col-form-label col-form-label-sm"
            >
              Email
            </label>
            <div className="col-sm-8">
              <input
                type="email"
                className="form-control form-control-sm"
                id="email"
                placeholder="Email"
                onChange={this.handleChange}
                name="email"
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              forname="colFormLabelSm"
              className="col-sm-2 col-form-label col-form-label-sm"
            >
              Contact Number
            </label>
            <div className="col-sm-8">
              <input
                type="number"
                className="form-control form-control-sm"
                id="cno"
                placeholder="Contact Number (Should be of 10 digits)"
                onChange={this.handleContact}
                name="cno"
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label col-form-label-sm">
              DOB
            </label>
            <div className="col-sm-8">
              <DatePicker onChange={this.onChange} value={this.state.date} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label col-form-label-sm">
              Location
            </label>
            <div className="col-sm-8">
              <span>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="location"
                  placeholder="location"
                  onChange={this.handleChange}
                  name="location"
                  value={this.state.location}
                  required
                />
              </span>
              <span
                className="btn btn-primary"
                // style={{ float: 'right' }}
                onClick={this.getcurrentlocation}
              >
                current location
              </span>
            </div>
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-primary ">
              Add Details
            </button>
          </div>
        </form> */}
            {/* <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                onChange={this.handleChange}
                id="exampleInputEmail1"
                name="fullname"
                placeholder="First Name"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                onChange={this.handleChange}
                id="exampleInputEmail1"
                name="email"
                placeholder="Enter email"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                onChange={this.handleChange}
                id="exampleInputEmail1"
                name="fullname"
                placeholder="Full Name"
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                onChange={this.handleChange}
                id="exampleInputPassword1"
                name="location"
                placeholder="Current Location"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Signup
            </button>
            <div>
              Already a member?<a href="/signin">Sign in</a>
            </div>
          </form> 
     </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default CreateUser;
