import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SideBar from '../components/SideBar';
import ThemeHeader from '../components/Header/ThemeHeader';
// import Modal from '../components/SimpleDialog/Modal';
import './createUser.css';

export default class Cms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  restartorthanc = async () => {
    this.setState({ loading: true });
    const url = 'https://pacs.space:5000/restart_orthanc';
    await fetch(url, {
      method: 'POST',
      headers: {
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())
      .catch(error => alert(error))
      .then(response => {
        console.log(response);
        if (response.success === true) {
          this.setState({ loading: false });
          alert(response.message);
        } else {
          alert(response.message);
        }
      });
  };
  render() {
    return (
      <div style={{ backgroundColor: 'white', maxHeight: '100vh' }}>
        <div>
          <ThemeHeader />
          <div className="container-fluid">
            <div className="row" style={{ backgroundColor: 'white' }}>
              <SideBar />

              <div
                className="col-9"
                style={{
                  marginLeft: '230px',
                  minHeight: 'calc(100vh - 69px)',
                  marginTop: '69px',
                  padding: '2% 15% 0 25%',
                }}
              >
                <div
                  className="p-2"
                  style={{
                    backgroundColor: '#F5F5F5',
                    textAlign: 'center',
                    borderColor: 'blue',
                  }}
                >
                  <Link
                    to={{
                      pathname: '/termsandconditions',
                    }}
                  >
                    Terms and Conditions
                  </Link>
                </div>
                <div
                  className="p-2"
                  style={{
                    backgroundColor: '#F5F5F5',
                    textAlign: 'center',
                    borderColor: 'blue',
                  }}
                >
                  <Link
                    to={{
                      pathname: '/privacypolicy',
                    }}
                  >
                    Privacy policy
                  </Link>
                </div>
                <div
                  className="p-2"
                  style={{
                    backgroundColor: '#F5F5F5',
                    textAlign: 'center',
                    borderColor: 'blue',
                  }}
                >
                  <Link
                    to={{
                      pathname: '/cookiepolicy',
                    }}
                  >
                    Cookie Policy
                  </Link>
                </div>
                <div
                  className="p-2"
                  style={{
                    backgroundColor: '#F5F5F5',
                    textAlign: 'center',
                    borderColor: 'blue',
                  }}
                >
                  <Link
                    to={{
                      pathname: '/add_modality',
                    }}
                  >
                    Add Modality
                  </Link>
                </div>
                {/* <div
                  className="p-2"
                  style={{
                    backgroundColor: '#F5F5F5',
                    textAlign: 'center',
                    borderColor: 'blue',
                  }}
                >
                  <Link
                    to={{
                      pathname: '/edit_modality',
                    }}
                  >
                    Edit Modality
                  </Link>
                </div> */}
                <div
                  className="p-2"
                  style={{
                    backgroundColor: '#F5F5F5',
                    textAlign: 'center',
                    borderColor: 'blue',
                  }}
                >
                  <Link
                    to={{
                      pathname: '/modality_listing',
                    }}
                  >
                    Modality List
                  </Link>
                </div>
                <div
                  className="p-2"
                  style={{
                    backgroundColor: '#F5F5F5',
                    textAlign: 'center',
                    borderColor: 'blue',
                  }}
                >
                  <Link
                    to={{
                      pathname: '/edit_orthanc_details',
                    }}
                  >
                    Edit Orthanc Details
                  </Link>
                </div>
                <div
                  className="p-2 hov"
                  style={{
                    color: 'blue',
                    backgroundColor: '#F5F5F5',
                    textAlign: 'center',
                    borderColor: 'blue',
                  }}
                >
                  <div
                    onClick={this.restartorthanc}
                    disabled={this.state.loading}
                  >
                    {this.state.loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: '5px' }}
                      />
                    )}
                    {this.state.loading && <span>Restarting...</span>}
                    {!this.state.loading && <span>Restart Orthanc </span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
