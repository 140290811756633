import React, { Component } from 'react';
import Modal from 'react-modal';
import './modal.css';
import { Link, Redirect } from 'react-router-dom';
export default class Modale extends Component {
  constructor(props) {
    super();

    this.state = {
      modalIsOpen: false,
      interest: [],
      redirect: false,
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleLogout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('type');
    this.setState({ redirect: true });
  };

  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/" />;
    }
    return (
      <div
        className="hov"
        style={{
          padding: '14px 10px',
          fontSize: '13px',
          fontFamily:
            'Roboto, OpenSans, HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif',
        }}
      >
        <i
          className="material-icons"
          style={{
            color: 'white',
            fontSize: '20px',
            marginRight: '-15px',
            position: 'relative',
            top: '2px',
          }}
        >
          dashboard
        </i>
        <span
          className="nav-label"
          onClick={this.openModal}
          style={{ color: 'white' }}
        >
          Logout
        </span>
        <Modal
          className="mod"
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          contentLabel="Example Modal"
        >
          <h3
            ref={subtitle => (this.subtitle = subtitle)}
            style={{ margin: '0 0 60px 0' }}
          >
            Are you sure you want to logout ?
          </h3>

          <div className="container">
            <div className="row">
              <div className="col-6">
                <button
                  className="btn btn-success"
                  style={{ width: '100%' }}
                  onClick={this.handleLogout}
                >
                  Yes
                </button>
              </div>

              <div className="col-6 w-100">
                <button
                  className="btn btn-danger"
                  style={{ width: '100%' }}
                  onClick={this.closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
