import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import SideBar from '../components/SideBar';
import ThemeHeader from '../components/Header/ThemeHeader';

export default class CookiePolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: 'hello',
      name: '/cookiepolicy',
      checkauth: '',
      authfailed: false,
    };
  }

  async componentDidMount() {
    const token = sessionStorage.getItem('token');
    this.setState({ checkauth: token });

    const url = 'https://pacs.space:5000/getpolicies';
    await fetch(url, {
      method: 'POST',
      headers: {
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())
      .catch(error => alert(error))
      .then(response => {
        console.log(response);
        if (response.message === 'Auth Failed') {
          this.setState({ authfailed: true });
        } else {
          this.setState({ data: response.data[0].cookiepolicy });
        }
      });
  }

  render() {
    if (this.state.authfailed && this.state.authfailed === true) {
      return (
        <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
          UnAuthorised Access
        </div>
      );
    } else {
      return (
        <div style={{ backgroundColor: 'white', maxHeight: '100vh' }}>
          <div>
            <ThemeHeader />
            <div className="container-fluid">
              <div className="row" style={{ backgroundColor: 'white' }}>
                <SideBar />

                <div
                  className="col-9"
                  style={{
                    marginLeft: '230px',
                    minHeight: 'calc(100vh - 69px)',
                    marginTop: '69px',
                    padding: '2% 5% 0px 15%',
                  }}
                >
                  <div
                    style={{
                      borderStyle: 'solid',
                      borderColor: 'lightgrey',
                      border: '1',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: 'lightgrey',
                        padding: '1px 0 0 3px',
                      }}
                    >
                      <h2>Cookie Policy</h2>
                    </div>

                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.data }}
                      style={{
                        padding: '5% 2% 10% 2% ',
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <span>
                      <Link
                        to={{
                          state: {
                            name: this.state.name,
                            data: this.state.data,
                          },
                          pathname: '/editor',
                        }}
                      >
                        <button
                          type="submit"
                          className="btn btn-sm btn-success pull-right "
                        >
                          Edit
                        </button>
                      </Link>
                    </span>
                    <span>
                      <Link
                        to={{
                          pathname: '/cms',
                        }}
                      >
                        <button className="btn btn-sm btn-success pull-right mx-1">
                          Back
                        </button>
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
