import React from 'react';
import './NotFound.css';
import { Link } from 'react-router-dom';

export default function NotFound({
  message = 'Sorry, You are not authorised.',
  showGoBackButton = true,
}) {
  return (
    <div className={'not-found'}>
      <div>
        <h4>{message}</h4>
        {showGoBackButton && (
          <h5>
            <Link to={'/'}>Go back to the Login</Link>
          </h5>
        )}
      </div>
    </div>
  );
}
