import React, { Component } from 'react';
import SideBar from '../components/SideBar';
import EditSubUser from '../components/Forms/EditSubUser';
import ThemeHeader from '../components/Header/ThemeHeader';

export default class EditSubAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }
  componentDidMount() {
    this.setState({ data: this.props.location.state.data });
  }
  render() {
    return (
      <div style={{ backgroundColor: 'white', maxHeight: '100vh' }}>
        <div>
          <ThemeHeader />
          <div className="container-fluid">
            <div className="row" style={{ backgroundColor: 'white' }}>
              <SideBar />

              <div
                className="col-9"
                style={{
                  marginLeft: '230px',
                  minHeight: 'calc(100vh - 69px)',
                  marginTop: '69px',
                  padding: '2% 15% 0 25%',
                }}
              >
                {this.state.data && <EditSubUser data={this.state.data} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
