import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// toast.configure();
export default class Emailotp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      validate: false,
    };
  }

  handleSubmit = () => {
    event.preventDefault();
    const url = 'https://pacs.space:5000/verifyemailsendotp';

    const data = {
      email: this.state.email.toLowerCase(),
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => res.json())
      .catch(
        error =>
          toast.error('Something went wrong', {
            position: toast.POSITION.TOP_CENTER,
          })
        // alert('Something went wrong')
      )
      .then(res => {
        // console.log(res);
        if (res.success === true) {
          toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
            onClose: () => {
              this.setState({ validate: true });
            },
          });
          // alert(res.message);
          // this.setState({ validate: true });
        } else {
          alert(res.message);
        }
      });
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    if (name.trim !== '') {
      this.setState({ [name]: value });
    } else {
      toast.warn(`${name} cannot be empty`, {
        position: toast.POSITION.TOP_CENTER,
      });
      // alert(`${name} cannot be empty`);
    }
  };

  render() {
    if (this.state.validate === true) {
      return (
        <Redirect
          to={{
            pathname: '/otp',
            state: { email: this.state.email },
          }}
        />
      );
    }
    return (
      <div style={{ backgroundColor: 'white', height: '100vh' }}>
        <div
          className="panel panel-default col-3 mx-auto"
          style={{ paddingTop: '10%' }}
        >
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label>Enter Yor Email to reset your password</label>
              <div className="input-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  onChange={this.handleChange}
                  placeholder="Enter your email"
                  required
                />
              </div>
              <button
                type="submit"
                className="btn btn-success w-100"
                style={{ marginTop: '25px' }}
              >
                verify Email
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
