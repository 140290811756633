import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default class SharedStudy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      validate: false,
      type: 'password',
      link: '/',
    };
  }

  // componentDidMount() {
  //   const a = sessionStorage.getItem('auth');
  //   console.log(a);
  //   {
  //     session && this.setState({ link: a });
  //   }
  // }
  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name.trim !== '') {
      this.setState({ [name]: value });
    } else {
      toast.warn(`${name} cannot be empty`, {
        position: toast.POSITION.TOP_CENTER,
      });
      // alert(`${name} cannot be empty`);
    }
  };

  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === 'text' ? 'password' : 'text',
    }));

  pwdValidation = () => {
    console.log('hello');
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
    if (reg.test(this.state.password)) {
      return true;
    } else {
      return false;
    }
  };

  handleSubmit = () => {
    event.preventDefault();
    const pwdValidation = this.pwdValidation();
    if (pwdValidation) {
      // const url = 'https://pacs.space:5000/signin';
      const url = 'https://pacs.space:5000/patientsignin';
      const data = {
        token: this.props.match.params.token,
        password: this.state.password.trim(),
      };
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((res) => res.json())
        .catch(
          (error) =>
            toast.error('Something went Wrong', {
              position: toast.POSITION.TOP_CENTER,
            })
          // alert('Something went Wrong')
        )
        .then((res) => {
          // console.log(res);
          if (res.success === true) {
            sessionStorage.setItem('type', res.data.type);
            sessionStorage.setItem('auth', JSON.stringify(res.auth));
            sessionStorage.setItem('sharedurl', res.data.sharedurl);
            sessionStorage.setItem('token', res.token);
            toast.success(res.message, {
              position: toast.POSITION.TOP_CENTER,
              onClose: () => this.setState({ validate: true }),
            });
          } else {
            toast.error(res.message, { position: toast.POSITION.TOP_CENTER });
            // alert(res.message);
          }
        });
    } else {
      toast.error(
        'Password will comprise of 1 digit, 1 special character and 1 upper case character',
        { position: toast.POSITION.TOP_CENTER }
      );
      // alert(
      //   'Password will comprise of 1 digit, 1 special character and 1 upper case character'
      // );
    }
  };

  // handleredux = () => {
  //   this.props.setCurrentUser('hello');
  // };

  render() {
    if (this.state.validate === true) {
      return <Redirect to="/studylist" />;
    }
    // <ConnectedSignin user="hello" />;
    return (
      <div style={{ backgroundColor: 'white', height: '100vh' }}>
        <div>
          <div
            className="panel panel-default col-3 mx-auto"
            style={{ paddingTop: '10%' }}
          >
            <img
              src="https://lh3.googleusercontent.com/-CmMZF4BhqvM/Xny0EijzbkI/AAAAAAAAF44/RL70Kp3o5koIqm9HbwkqOR_t9BGpq47zACK8BGAsYHg/s0/2020-03-26.jpg"
              style={{
                width: 'inherit',
                float: 'left',
                margin: '0 5px 25px 0',
              }}
              alt=""
            />
            {/* <button
                  onClick={() => {
                    this.props.changename('suresh');
                  }}
                >
                  hello
                </button> */}
            <div className="panel-body">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Password</label>
                  <div className="input-group">
                    <input
                      type={this.state.type}
                      className="form-control"
                      name="password"
                      onChange={this.handleChange}
                      placeholder="Your password"
                      required
                    />
                    <span
                      className="input-group-addon"
                      style={{ width: '40px' }}
                      onClick={this.handleClick}
                    >
                      {this.state.type === 'text' ? (
                        <i
                          className="fa fa-eye"
                          aria-hidden="true"
                          style={{ padding: '11px' }}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye-slash"
                          aria-hidden="true"
                          style={{ padding: '11px' }}
                        ></i>
                      )}
                    </span>
                  </div>
                </div>

                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-success w-100"
                    style={{ marginTop: '25px' }}
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
