import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

export default class EditModalityDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      aet: '',
      port: '',
    };
  }

  componentDidMount() {
    // console.log('hello');
    const url = 'https://pacs.space:5000/getorthancdetails';
    fetch(url, {
      method: 'POST',
      headers: {
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
          aet: res.data.aet,
          port: res.data.port,
        });
      })
      .catch(error => alert('Try again'));

    // console.log(this.props.data);
  }

  // onChange = date => this.setState({ date });

  apicall = () => {
    // const url = 'http://159.89.173.21:8000/addsubuser';
    const url = 'https://pacs.space:5000/editorthancdetails';
    const data = {
      aet: this.state.aet && this.state.aet,

      port: this.state.port && this.state.port,
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then(res => res.json())
      .catch(error => alert('Something went wrong try again'))
      .then(res => {
        if (res.message === 'Auth Failed') {
          this.setState({ authfailed: true });
        } else {
          if (res.success === true) {
            alert('Succesfully added');
            this.setState({ redirect: true });
          }
          if (res.success === false) {
            alert(res.message);
          }
        }
      });
  };

  validation = () => {
    event.preventDefault();
    this.apicall();

    // const { dicomserver } = this.state.data;
    // if (dicomserver.trim() === '') {
    //   alert('dicom server cannot be empty');
    // }
    // // else if (lname.trim() === '') {
    // //   alert('Last Name cannot be empty');
    // // } else if (date === null) {
    // //   alert('Date cannot be empty');
    // // }
    // else {

    // }
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({ [name]: value });
  };

  render() {
    if (this.state.authfailed && this.state.authfailed === true) {
      return (
        <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
          UnAuthorised Access
        </div>
      );
    } else {
      if (this.state.redirect === true) {
        return <Redirect to="/cms" />;
      }
      return (
        <div className="panel panel-default">
          <div className="panel-heading" style={{ fontSize: '20px' }}>
            Edit Modality
          </div>
          <div className="panel-body">
            <form id="form-horizontal" onSubmit={this.validation}>
              <div className="form-group">
                <label>IP Address</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="ip"
                  placeholder=""
                  onChange={this.handleChange}
                  value="206.189.200.142"
                  name="ip"
                  disabled
                />
              </div>
              <div className="form-group">
                <label>AET </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="aet"
                  placeholder=""
                  onChange={this.handleChange}
                  value={this.state.aet && this.state.aet}
                  name="aet"
                  required
                />
              </div>
              {/* <div className="col-sm-3">
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    id="ip2"
                    placeholder=""
                    onChange={this.handleChange}
                    name="ip2"
                    required
                  />
                </div>
                <div className="col-sm-3">
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    id="ip3"
                    placeholder=""
                    onChange={this.handleChange}
                    name="ip3"
                    required
                  />
                </div>
                <div className="col-sm-3">
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    id="ip4"
                    placeholder=""
                    onChange={this.handleChange}
                    name="ip4"
                    required
                  />
                </div> */}

              <div className="form-group">
                <label> Port</label>
                <input
                  type="number"
                  className="form-control form-control-sm"
                  id="port"
                  onChange={this.handleChange}
                  value={this.state.port && this.state.port}
                  name="port"
                  required
                />
              </div>
              <div className="form-group">
                <span>
                  <button
                    type="submit"
                    className="btn btn-sm btn-success pull-right"
                  >
                    Submit
                  </button>
                </span>
                <span>
                  <Link
                    to={{
                      pathname: '/cms',
                    }}
                  >
                    <button
                      type="submit"
                      className="btn btn-sm btn-success pull-right mx-1"
                    >
                      Cancel
                    </button>
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }
  }
}
