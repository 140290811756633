import React, { Component } from 'react';
// import DatePicker from 'react-date-picker';
import { Redirect, Link } from 'react-router-dom';

export default class AddDoctorDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      email: null,
      cno: null,
      lname: null,
      fname: null,
      redirect: false,
      location: null,
      hospitalname: null,
      designation: null,
      authfailed: false,
    };
  }

  // setlocation = Location => {
  //   this.setState({
  //     location: Location,
  //   });
  // };

  // getcurrentlocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(position => {
  //       console.log(position);
  //       fetch(
  //         'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
  //           position.coords.latitude +
  //           ',' +
  //           position.coords.longitude +
  //           '&key=AIzaSyCIeyrzeVetCiVKGvqLtNl4ocP5zX_E1jo'
  //       )
  //         .then(res => res.json())
  //         .catch(error => alert(error))
  //         .then(res => {
  //           if (res) {
  //             var Location = res.results[0].formatted_address;
  //             this.setlocation(Location);
  //           }
  //         });
  //     });
  //   }
  // };
  onChange = date => this.setState({ date });

  apicall = () => {
    const url = 'https://pacs.space:5000/addDoctordetails';
    // const url = 'http://159.89.173.21:8000/addDetails';
    const data = {
      lname: this.state.lname.trim(),
      fname: this.state.fname.trim(),
      email: this.state.email.toLowerCase(),
      cno: this.state.cno.trim(),
      date: this.state.date,
      hospitalname: this.state.hospitalname,
      designation: this.state.designation,
      location: this.state.location,
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then(res => res.json())
      .catch(error => alert('Something went wrong try again'))
      .then(res => {
        if (res.message === 'Auth Failed') {
          this.setState({ authfailed: true });
        } else {
          if (res.success === true) {
            alert('Succesfully added');
            this.setState({ redirect: true });
          }
          if (res.success === false) {
            alert(res.message);
          }
        }
      });
  };

  validation = () => {
    event.preventDefault();
    const {
      fname,
      lname,
      cno,
      date,
      location,
      designation,
      hospitalname,
    } = this.state;
    // var compare = '/^[0-9]{1,10}$/g';
    if (fname.trim() === '') {
      alert('First Name cannot be empty');
    } else if (lname.trim() === '') {
      alert('Last Name cannot be empty');
    } else if (designation.trim() === '') {
      alert('designation cannot be empty');
    } else if (hospitalname.trim() === '') {
      alert('hospital Name cannot be empty');
    } else if (location.trim() === '') {
      alert('Location cannot be empty');
    } else if (date === null) {
      alert('Date cannot be empty');
    } else if (cno.trim() === '' || date === null) {
      alert('Contact Number should contain spaces');
    }
    // else if (!cno.match(compare)) {
    //   alert('contact number should contain digits only');
    // }
    else if (cno.length !== 10) {
      alert('contact number should be of 10 digits');
    } else {
      this.apicall();
    }
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({ [name]: value });
  };

  handleContact = event => {
    // const name = event.target.name;
    // const value = event.target.value;
    var cno =
      event.target.validity.valid || event.target.value == ''
        ? event.target.value
        : this.state.cno;

    this.setState({ cno: cno });
  };

  render() {
    if (this.state.authfailed && this.state.authfailed === true) {
      return (
        <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
          UnAuthorised Access
        </div>
      );
    } else {
      if (this.state.redirect === true) {
        return <Redirect to="/doctors" />;
      }
      return (
        <div className="panel panel-default">
          <div className="panel-heading" style={{ fontSize: '20px' }}>
            Add Doctor
          </div>
          <div className="panel-body" style={{ paddingBottom: '15%' }}>
            <form id="form-horizontal" onSubmit={this.validation}>
              <div className="form-group">
                <label>Name</label>
                <div className="form-group row">
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="fname"
                      placeholder="First Name"
                      onChange={this.handleChange}
                      name="fname"
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="lname"
                      placeholder="Last Name"
                      onChange={this.handleChange}
                      name="lname"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Email Address</label>
                <input
                  type="email"
                  className="form-control form-control-sm"
                  id="email"
                  placeholder="Email"
                  onChange={this.handleChange}
                  name="email"
                  required
                />
              </div>
              <div className="form-group">
                <label>Contact Number</label>
                <input
                  type="text"
                  pattern="[0-9]*"
                  className="form-control form-control-sm"
                  maxLength="10"
                  id="cno"
                  placeholder="Contact Number (Should be of 10 digits)"
                  onChange={this.handleContact}
                  value={this.state.cno}
                  required
                />
              </div>
              <div className="form-group">
                <label>Hospital Name</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="hosname"
                  placeholder="Hospital Name"
                  onChange={this.handleChange}
                  name="hospitalname"
                  required
                />
              </div>
              <div className="form-group">
                <label>Designation</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="desig"
                  placeholder="Designation"
                  onChange={this.handleChange}
                  name="designation"
                  required
                />
              </div>

              <div className="form-group">
                <label>Location</label>
                {/* <div className="input-group"> */}
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="location"
                  placeholder="location"
                  onChange={this.handleChange}
                  name="location"
                  value={this.state.location}
                  required
                />
                {/* <span
                    className="input-group-addon btn btn-sm btn-success pull-right mx-1"
                    // style={{ float: 'right' }}
                    onClick={this.getcurrentlocation}
                  >
                    current location
                  </span> */}
                {/* </div> */}
              </div>
              <div className="form-group">
                <span>
                  <button
                    type="submit"
                    className="btn btn-sm btn-success pull-right"
                  >
                    Submit
                  </button>
                </span>
                <span>
                  <Link
                    to={{
                      pathname: '/doctors',
                    }}
                  >
                    <button
                      type="submit"
                      className="btn btn-sm btn-success pull-right mx-1"
                    >
                      Cancel
                    </button>
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }
  }
}
