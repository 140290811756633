import React, { Component } from 'react';
import ThemeHeader from '../components/Header/ThemeHeader';
import SideBar from '../components/SideBar';
import EditOrthancDetails from '../components/Forms/EditOrthancDetails';

export default class EditModality extends Component {
  render() {
    return (
      <div style={{ backgroundColor: 'white', maxHeight: '100vh' }}>
        <div>
          <ThemeHeader />
          <div className="container-fluid">
            <div className="row" style={{ backgroundColor: 'white' }}>
              <SideBar />

              <div
                className="col-9"
                style={{
                  marginLeft: '230px',
                  minHeight: 'calc(100vh - 69px)',
                  marginTop: '69px',
                  padding: '2% 15% 0 25%',
                }}
              >
                <EditOrthancDetails />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
