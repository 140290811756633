import React, { Component } from 'react';
// import DatePicker from 'react-date-picker';
import { Redirect, Link } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
export default class EditDoctorDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      redirect: false,
      authfailed: false,
    };
  }

  componentDidMount() {
    this.setState({ data: this.props.data });
  }

  // setlocation = Location => {
  //   let newArr = {
  //     ...this.state.data,
  //     location: Location,
  //   };
  //   this.setState({ data: newArr });
  // };

  // getcurrentlocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(position => {
  //       console.log(position);
  //       fetch(
  //         'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
  //           position.coords.latitude +
  //           ',' +
  //           position.coords.longitude +
  //           '&key=AIzaSyCIeyrzeVetCiVKGvqLtNl4ocP5zX_E1jo'
  //       )
  //         .then(res => res.json())
  //         .catch(error => alert(error))
  //         .then(res => {
  //           if (res) {
  //             Location = res.results[0].formatted_address;
  //             this.setlocation(Location);
  //           }
  //         });
  //     });
  //   } else {
  //     alert('navigator geolocation');
  //   }
  // };

  apicall = () => {
    const url = 'https://pacs.space:5000/editdoctordetails';
    // const url = 'http://159.89.173.21:8000/addDetails';
    const data = {
      lname: this.state.data.lname.trim(),
      fname: this.state.data.fname.trim(),
      location: this.state.data.location,
      cno: this.state.data.cno.trim(),
      hospitalname: this.state.data.hospitalname.trim(),
      designation: this.state.data.designation.trim(),
      id: this.state.data.id,
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then(res => res.json())
      .catch(error => alert('Something went wrong try again'))
      .then(res => {
        if (res.message === 'Auth Failed') {
          this.setState({ authfailed: true });
        } else {
          if (res.success === true) {
            // toast.success('Succesfully added', {
            //   position: toast.POSITION.TOP_CENTER,
            //   onClose: () => this.setState({ redirect: true }),
            // });
            alert('Succesfully added');
            this.setState({ redirect: true });
          }
          if (res.success === false) {
            // toast.error(res.message, {
            //   position: toast.POSITION.TOP_CENTER,
            // });
            alert(res.message);
          }
        }
      });
  };

  validation = () => {
    event.preventDefault();
    const {
      fname,
      lname,
      cno,
      hospitalname,
      designation,
      location,
    } = this.state.data;
    // var compare = '/^[0-9]{1,10}$/g';
    if (fname.trim() === '' || fname === null) {
      // toast.warn(`First name cannot be empty`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      alert('First name cannot be empty');
    } else if (lname.trim() === '' || lname === null) {
      // toast.warn(`$ Last name cannot be empty`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      alert('Last name cannot be empty');
    } else if (hospitalname.trim() === '' || hospitalname === null) {
      // toast.warn(`$ Hospital name cannot be empty`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      alert('Hospital name cannot be empty');
    } else if (designation.trim() === '' || designation === null) {
      // toast.warn(`$ Designation cannot be empty`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      alert('Designation cannot be empty');
    }
    // else if (!cno.match(compare)) {
    //   toast.warn(`$ Contact number should contain digits only`, {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    // }
    else if (location.trim() === '' || location === null) {
      // toast.warn(`$ Location cannot be empty`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      alert('Location cannot be empty');
    }
    //  else if (date === null) {
    //   toast.warn(`$ date cannot be empty`, {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    // }
    // else if (cno.trim() === '' || cno === null) {
    //   toast.warn(`$ Contact Number should contain spaces`, {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    // }
    else if (cno.length !== 10) {
      // toast.warn(`contact number should be of 10 digits`, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      alert('contact number should be of 10 digits');
    } else {
      this.apicall();
    }
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    let newArr = {
      ...this.state.data,
      [name]: value,
    };
    this.setState({ data: newArr });
  };

  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/doctors" />;
    }
    return (
      <div className="panel panel-default ">
        <div className="panel-heading" style={{ fontSize: '20px' }}>
          Edit Doctor Details
        </div>
        <div className="panel-body" style={{ paddingBottom: '50px' }}>
          <form id="form_basic_validation" onSubmit={this.validation}>
            <div className="form-group">
              <label>Name</label>
              <div className="form-group row">
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="fname"
                    placeholder="First Name"
                    onChange={this.handleChange}
                    name="fname"
                    value={this.state.data && this.state.data.fname}
                    required
                  />
                </div>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="lname"
                    placeholder="Last Name"
                    onChange={this.handleChange}
                    name="lname"
                    value={this.state.data && this.state.data.lname}
                    required
                  />
                </div>
              </div>
            </div>
            {/* <div className="form-group">
              <label>First Name</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="fname"
                placeholder="First Name"
                onChange={this.handleChange}
                name="fname"
                required
              />
            </div>
            <div className="form-group"> */}
            {/* <label>Last Name</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="lname"
                placeholder="Last Name"
                onChange={this.handleChange}
                name="lname"
                required
              />
            </div> */}
            <div className="form-group">
              <label>Email Address</label>
              <input
                type="email"
                className="form-control form-control-sm"
                id="email"
                value={this.state.data && this.state.data.email}
                placeholder="Email"
                onChange={this.handleChange}
                name="email"
                disabled
              />
            </div>
            <div className="form-group">
              <label>Location</label>
              {/* <div className="input-group"> */}
              <input
                type="text"
                className="form-control form-control-sm"
                id="location"
                placeholder="location"
                onChange={this.handleChange}
                name="location"
                value={this.state.data && this.state.data.location}
                required
              />
              {/* <span
                  className="input-group-addon btn btn-sm btn-success pull-right mx-1"
                  // style={{ float: 'right' }}
                  onClick={this.getcurrentlocation}
                >
                  current location
                </span> */}
              {/* </div> */}
            </div>
            <div className="form-group">
              <label>Hospital Name</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="hospitalname"
                placeholder="Enter Hospital Name"
                onChange={this.handleChange}
                value={this.state.data && this.state.data.hospitalname}
                name="hospitalname"
                required
              />
            </div>
            <div className="form-group">
              <label>Designation</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="desig"
                placeholder="Designation"
                onChange={this.handleChange}
                value={this.state.data && this.state.data.designation}
                name="designation"
                required
              />
            </div>
            <div className="form-group">
              <label>Contact Number</label>
              <input
                type="number"
                className="form-control form-control-sm"
                id="cno"
                placeholder="Contact Number (Should be of 10 digits)"
                onChange={this.handleChange}
                value={this.state.data && this.state.data.cno}
                name="cno"
                required
              />
            </div>
            <div className="form-group">
              <span>
                <button
                  type="submit"
                  className="btn btn-sm btn-success pull-right"
                >
                  Submit
                </button>
              </span>
              <span>
                <Link
                  to={{
                    pathname: '/doctors',
                  }}
                >
                  <button
                    type="submit"
                    className="btn btn-sm btn-success pull-right mx-1"
                  >
                    Cancel
                  </button>
                </Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
