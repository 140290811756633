import asyncComponent from '../components/AsyncComponent.js';
import Createuser from './Createuser';
import UpdateUser from './UpdateUser';
// import PatientList from './PatientList';
import Signin from './Signin';
import RecoverPwd from './RecoverPwd';
import Emailotp from './Emailotp';
import otp from './otp';
import UploadDicomImage from './UploadDicomImage';
import Doctor from './Doctor';
import OHIF from '@ohif/core';
// import EditPatient from './EditPatient.js';
import AddDoctor from './AddDoctor.js';
import EditDoctor from './EditDoctor.js';
import TermsConditions from './TermsConditions.js';
import PrivacyPolicy from './PrivacyPolicy.js';
import CookiePolicy from './CookiePolicy.js';
import Editor from './Editor.js';
import Cms from './Cms.js';
import SubAdmin from './SubAdmin.js';
import SubAdminList from './SubAdminList.js';
import EditSubAdmin from './EditSubAdmin.js';
import EditModality from './EditModality.js';
import AddModality from './AddModality.js';
import ModalityList from './ModalityList.js';
import ChangePassword from './ChangePassword.js';
import ChangeOrthancDetails from './ChangeOrthancDetails';
import UserData from './UserData';
import SharedStudy from './SharedStudy';
import Branching from './Branching';

const { urlUtil: UrlUtil } = OHIF.utils;

// Dynamic Import Routes (CodeSplitting)
const IHEInvokeImageDisplay = asyncComponent(() =>
  import(
    /* webpackChunkName: "IHEInvokeImageDisplay" */ './IHEInvokeImageDisplay.js'
  )
);
const ViewerRouting = asyncComponent(() =>
  import(/* webpackChunkName: "ViewerRouting" */ './ViewerRouting.js')
);

const StudyListRouting = asyncComponent(() =>
  import(
    /* webpackChunkName: "StudyListRouting" */ '../studylist/StudyListRouting.js'
  )
);
const StandaloneRouting = asyncComponent(() =>
  import(
    /* webpackChunkName: "ConnectedStandaloneRouting" */ '../connectedComponents/ConnectedStandaloneRouting.js'
  )
);
const ViewerLocalFileData = asyncComponent(() =>
  import(
    /* webpackChunkName: "ViewerLocalFileData" */ '../connectedComponents/ViewerLocalFileData.js'
  )
);

const reload = () => window.location.reload();

const ROUTES_DEF = {
  default: {
    viewer: {
      path: '/viewer/:studyInstanceUids',

      component: ViewerRouting,
    },
    sharedstudy: {
      path: '/shared/:token',
      component: SharedStudy,
    },
    createuser: {
      path: '/createuser',
      component: Createuser,
    },
    updateuser: {
      path: '/updateuser',
      component: UpdateUser,
    },
    // patientlist: {
    //   path: '/patient_listing',
    //   component: PatientList,
    // },

    uploaddicomimage: {
      path: '/upload_dicom_image',
      component: UploadDicomImage,
    },

    branching: {
      path: '/branching',
      component: Branching,
    },

    signin: {
      path: '/',
      component: Signin,
    },

    // editpatientdetails: {
    //   path: '/editpatientdetails',
    //   component: EditPatient,
    // },

    doctor: {
      path: '/doctors',
      component: Doctor,
    },

    userdata: {
      path: '/users',
      component: UserData,
    },

    cms: {
      path: '/cms',
      component: Cms,
    },

    subadmin: {
      path: '/subadmin',
      component: SubAdmin,
    },

    subadminlist: {
      path: '/subadmin_listing',
      component: SubAdminList,
    },

    editsubadmin: {
      path: '/editsubadmindetails',
      component: EditSubAdmin,
    },

    termsandcondition: {
      path: '/termsandconditions',
      component: TermsConditions,
    },

    editor: {
      path: '/editor',
      component: Editor,
    },

    privacypolicy: {
      path: '/privacypolicy',
      component: PrivacyPolicy,
    },

    cookiepolicy: {
      path: '/cookiepolicy',
      component: CookiePolicy,
    },

    editmodality: {
      path: '/edit_modality',
      component: EditModality,
    },

    editorthancdetails: {
      path: '/edit_orthanc_details',
      component: ChangeOrthancDetails,
    },

    addmodality: {
      path: '/add_modality',
      component: AddModality,
    },

    modalitylist: {
      path: '/modality_listing',
      component: ModalityList,
    },

    adddoctordetails: {
      path: '/adddoctordetails',
      component: AddDoctor,
    },

    editdoctordetails: {
      path: '/editdoctordetails',
      component: EditDoctor,
    },

    changepassword: {
      path: '/changepassword',
      component: ChangePassword,
    },

    RecoverPwd: {
      path: '/recover_password',
      component: RecoverPwd,
    },

    emailotp: {
      path: '/emailotp',
      component: Emailotp,
    },

    otp: {
      path: '/otp',
      component: otp,
    },

    standaloneViewer: {
      path: '/viewer',
      component: StandaloneRouting,
    },
    list: {
      path: '/studylist',
      component: StudyListRouting,
      condition: (appConfig) => {
        return appConfig.showStudyList !== undefined
          ? appConfig.showStudyList
          : true;
      },
    },
    local: {
      path: '/local',
      component: ViewerLocalFileData,
    },
    IHEInvokeImageDisplay: {
      path: '/IHEInvokeImageDisplay',
    },
  },
  gcloud: {
    viewer: {
      path:
        '/projects/:project/locations/:location/datasets/:dataset/dicomStores/:dicomStore/study/:studyInstanceUids',
      component: ViewerRouting,
      condition: (appConfig) => {
        return !!appConfig.enableGoogleCloudAdapter;
      },
    },
    list: {
      path:
        '/projects/:project/locations/:location/datasets/:dataset/dicomStores/:dicomStore',
      component: StudyListRouting,
      condition: (appConfig) => {
        const showList =
          appConfig.showStudyList !== undefined
            ? appConfig.showStudyList
            : true;

        return showList && !!appConfig.enableGoogleCloudAdapter;
      },
    },
  },
};

const getRoutes = (appConfig) => {
  const routes = [];
  for (let keyConfig in ROUTES_DEF) {
    const routesConfig = ROUTES_DEF[keyConfig];

    for (let routeKey in routesConfig) {
      const route = routesConfig[routeKey];
      const validRoute =
        typeof route.condition === 'function'
          ? route.condition(appConfig)
          : true;

      if (validRoute) {
        routes.push({
          path: route.path,
          Component: route.component,
        });
      }
    }
  }

  return routes;
};

const parsePath = (path, server, params) => {
  let _path = path;
  const _paramsCopy = Object.assign({}, server, params);

  for (let key in _paramsCopy) {
    _path = UrlUtil.paramString.replaceParam(_path, key, _paramsCopy[key]);
  }

  return _path;
};

const parseViewerPath = (appConfig = {}, server = {}, params) => {
  let viewerPath = ROUTES_DEF.default.viewer.path;
  if (appConfig.enableGoogleCloudAdapter) {
    viewerPath = ROUTES_DEF.gcloud.viewer.path;
  }

  return parsePath(viewerPath, server, params);
};

const parseStudyListPath = (appConfig = {}, server = {}, params) => {
  let studyListPath = ROUTES_DEF.default.list.path;
  if (appConfig.enableGoogleCloudAdapter) {
    studyListPath = ROUTES_DEF.gcloud.list.path || studyListPath;
  }

  return parsePath(studyListPath, server, params);
};

export { getRoutes, parseViewerPath, parseStudyListPath, reload };
