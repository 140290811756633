import React, { Component } from 'react';

export default class ThemeHeader extends Component {
  render() {
    return (
      <header>
        <nav
          className="navbar navbar-default"
          style={{ width: '100%', position: 'fixed' }}
        >
          <div className="container-fluid">
            <div className="navbar-header">
              <a
                href="javascript:void(0);"
                className="left-toggle-left-sidebar js-left-toggle-left-sidebar"
              >
                <i className="material-icons">menu</i>
              </a>
              {/* Logo */}
              <a className="navbar-brand" href="index.html">
                <span className="logo-minimized">MS</span>
                <span className="logo">
                  <h4>Medisurf</h4>
                </span>
              </a>
              {/* #END# Logo */}
            </div>
            {/* <div
                className="collapse navbar-collapse show col-11"
                id="navbar-collapse"
              >
                <ul className="nav navbar-nav">
                  <li>
                    <a
                      href="javascript:void(0);"
                      className="toggle-left-sidebar js-toggle-left-sidebar"
                    >
                      <i className="material-icons">menu</i>
                    </a>
                  </li>
                </ul>
              </div> */}
          </div>
        </nav>
      </header>
    );
  }
}
