import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modale from './SimpleDialog/Modal.js';

export default class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: '/',
      routesarray: [],
      type: '',
    };
  }

  componentDidMount() {
    const arr = sessionStorage.getItem('auth');
    const type = sessionStorage.getItem('type');
    this.setState({ routesarray: arr, type });
    // console.log(sessionStorage.getItem('type'));
  }

  render() {
    return (
      <div>
        <aside
          className="sidebar"
          style={{
            marginTop: '69px',
            position: 'fixed',
            height: '100%',
          }}
        >
          {(this.state.type && this.state.type === 'subadmin') ||
          this.state.type === 'doctor' ? (
            <nav className="sidebar-nav">
              <ul className="metismenu">
                <li className="title">MAIN NAVIGATION</li>
                {this.state.routesarray.includes('/studylist') ? (
                  <li className="active">
                    <div className="menu">
                      <Link
                        to={{
                          pathname: '/studylist',
                        }}
                      >
                        <i className="material-icons">dashboard</i>

                        <span className="nav-label">Study List</span>
                      </Link>
                    </div>
                  </li>
                ) : (
                  ''
                )}
                {this.state.routesarray.includes('/upload_dicom_image') ? (
                  <li className="active">
                    <div className="menu">
                      <Link
                        to={{
                          pathname: '/upload_dicom_image',
                        }}
                      >
                        <i className="material-icons">dashboard</i>

                        <span className="nav-label">Upload Exam</span>
                      </Link>
                    </div>
                  </li>
                ) : (
                  ''
                )}

                {/* {this.state.routesarray.includes('/patient_listing') ? (
                  // <li className="active">
                  //   <div className="menu">
                  //     <Link
                  //       to={{
                  //         pathname: '/patient_listing',
                  //       }}
                  //     >
                  //       <i className="material-icons">dashboard</i>

                  //       <span className="nav-label">Patient</span>
                  //     </Link>
                  //   </div>
                  // </li>
                ) : (
                  ''
                )} */}
                {this.state.routesarray.includes('/doctors') ? (
                  <li className="active">
                    <div className="menu">
                      <Link
                        to={{
                          pathname: '/doctors',
                        }}
                      >
                        <i className="material-icons">dashboard</i>

                        <span className="nav-label">Doctor</span>
                      </Link>
                    </div>
                  </li>
                ) : (
                  ''
                )}
                {this.state.routesarray.includes('/changepassword') ? (
                  <li className="active">
                    <div className="menu">
                      <Link
                        to={{
                          pathname: '/changepassword',
                        }}
                      >
                        <i className="material-icons">dashboard</i>

                        <span className="nav-label">Change Password</span>
                      </Link>
                    </div>
                  </li>
                ) : (
                  ''
                )}
                {this.state.routesarray.includes('/subadmin_listing') ? (
                  <li className="active">
                    <div className="menu">
                      <Link
                        to={{
                          pathname: '/subadmin_listing',
                        }}
                      >
                        <i className="material-icons">dashboard</i>

                        <span className="nav-label">Power User</span>
                      </Link>
                    </div>
                  </li>
                ) : (
                  ''
                )}
                {this.state.routesarray.includes('/cms') ? (
                  <li className="active">
                    <div className="menu">
                      <Link
                        to={{
                          pathname: '/cms',
                        }}
                      >
                        <i className="material-icons">dashboard</i>

                        <span className="nav-label">Settings</span>
                      </Link>
                    </div>
                  </li>
                ) : (
                  ''
                )}

                <li className="active">
                  <Modale />
                </li>
              </ul>
            </nav>
          ) : (
            <nav className="sidebar-nav">
              <ul className="metismenu">
                <li className="title">MAIN NAVIGATION</li>
                <li className="active">
                  <div className="menu">
                    <Link
                      to={{
                        pathname: '/studylist',
                      }}
                    >
                      <i className="material-icons">dashboard</i>

                      <span className="nav-label">Study List</span>
                    </Link>
                  </div>
                </li>
                <li className="active">
                  <div className="menu">
                    <Link
                      to={{
                        pathname: '/upload_dicom_image',
                      }}
                    >
                      <i className="material-icons">dashboard</i>

                      <span className="nav-label">Upload Exam</span>
                    </Link>
                  </div>
                </li>
                <li className="active">
                  <div className="menu">
                    <Link
                      to={{
                        pathname: '/branching',
                      }}
                    >
                      <i className="material-icons">dashboard</i>

                      <span className="nav-label">Branching</span>
                    </Link>
                  </div>
                </li>

                {/* <li className="active">
                  <div className="menu">
                    <Link
                      to={{
                        pathname: '/patient_listing',
                      }}
                    >
                      <i className="material-icons">dashboard</i>

                      <span className="nav-label">Patient</span>
                    </Link>
                  </div>
                </li> */}
                <li className="active">
                  <div className="menu">
                    <Link
                      to={{
                        pathname: '/doctors',
                      }}
                    >
                      <i className="material-icons">dashboard</i>

                      <span className="nav-label">Doctor</span>
                    </Link>
                  </div>
                </li>
                <li className="active">
                  <div className="menu">
                    <Link
                      to={{
                        pathname: '/subadmin_listing',
                      }}
                    >
                      <i className="material-icons">dashboard</i>

                      <span className="nav-label">Power User</span>
                    </Link>
                  </div>
                </li>
                <li className="active">
                  <div className="menu">
                    <Link
                      to={{
                        pathname: '/cms',
                      }}
                    >
                      <i className="material-icons">dashboard</i>

                      <span className="nav-label">Settings</span>
                    </Link>
                  </div>
                </li>
                <li className="active">
                  <div className="menu">
                    <Link
                      to={{
                        pathname: '/changepassword',
                      }}
                    >
                      <i className="material-icons">dashboard</i>

                      <span className="nav-label">Change Password</span>
                    </Link>
                  </div>
                </li>
                {this.state.routesarray.includes('/users') ? (
                  <li className="active">
                    <div className="menu">
                      <Link
                        to={{
                          pathname: '/users',
                        }}
                      >
                        <i className="material-icons">dashboard</i>

                        <span className="nav-label">Data Analytics</span>
                      </Link>
                    </div>
                  </li>
                ) : (
                  ''
                )}
                <li className="active">
                  <Modale />
                </li>
              </ul>
            </nav>
          )}
        </aside>
      </div>
    );
  }
}
