import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class RecoverPwd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      validate: false,
      confirmpassword: '',
      type: 'password',
      error: false,
      match: false,
      email: '',
    };
  }
  componentDidMount() {
    console.log(this.props.location.state);
    this.setState({
      email: this.props.location.state.email,
    });
    console.log(this.state.email);
  }

  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === 'text' ? 'password' : 'text',
    }));

  pwdValidation = () => {
    console.log('hello');
    if (
      this.state.password.trim() !== '' &&
      this.state.password === this.state.confirmpassword
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleChangePassword = () => {
    const name = event.target.name;
    const value = event.target.value;
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
    if (reg.test(value)) {
      this.setState({ error: true, password: value });
    } else {
      this.setState({ error: false });
    }
  };

  handleChangeConfirmPassword = () => {
    const name = event.target.name;
    const value = event.target.value;
    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
    if (reg.test(value) && value === this.state.password) {
      this.setState({ match: true, [name]: value });
    } else {
      this.setState({ match: false });
    }
  };

  handleSubmit = () => {
    event.preventDefault();
    const pwdValidation = this.pwdValidation();
    if (pwdValidation) {
      const url = 'https://pacs.space:5000/recoverpassword';
      const data = {
        password: this.state.password.trim(),
        email: this.state.email,
      };
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(res => res.json())
        .catch(
          error =>
            toast.error('Something went wrong try again', {
              position: toast.POSITION.TOP_CENTER,
            })
          // alert('Something went wrong try again')
        )
        .then(res => {
          if (res) {
            toast.success(res.message, {
              position: toast.POSITION.TOP_CENTER,
              onClose: () => this.setState({ validate: true }),
            });
            // alert(res.message);
            this.setState({ validate: true });
          } else {
            toast.error(res.message, { position: toast.POSITION.TOP_CENTER });
            // alert(res.message);
          }
        });
    } else if (this.state.password !== this.state.confirmpassword) {
      toast.warn('password mismatch', { position: toast.POSITION.TOP_CENTER });
    } else {
      toast.warn(
        'Password will comprise of 1 digit, 1 special character and 1 upper case character',
        { position: toast.POSITION.TOP_CENTER }
      );
      // alert(
      //   'Password will comprise of 1 digit, 1 special character and 1 upper case character'
      // );
    }
  };

  render() {
    if (this.state.validate === true) {
      return <Redirect to="/" />;
    }
    return (
      <div style={{ backgroundColor: 'white', height: '100vh' }}>
        <form onSubmit={this.handleSubmit}>
          <div
            className="panel panel-default col-3 mx-auto"
            style={{ paddingTop: '10%' }}
          >
            <h1 style={{ padding: '25px' }}>Create New Password</h1>
            <div className="form-group">
              <label>New Password</label>
              <div className="input-group">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  onChange={this.handleChangePassword}
                  placeholder="Your password"
                  required
                />
              </div>
              <div>
                {this.state.error === false ? (
                  <p style={{ fontSize: 'small' }}>
                    (Password should contain upper case, digit and 1 special
                    character)
                  </p>
                ) : (
                  <p style={{ fontSize: 'small' }}>Perfect</p>
                )}
              </div>
              <label>Confirm Password</label>
              <div className="input-group">
                <input
                  type={this.state.type}
                  className="form-control"
                  name="confirmpassword"
                  onChange={this.handleChangeConfirmPassword}
                  placeholder="Your password"
                  required
                />
                <span
                  className="input-group-addon"
                  style={{ width: '40px' }}
                  onClick={this.handleClick}
                >
                  {this.state.type === 'text' ? (
                    <i
                      className="fa fa-eye"
                      aria-hidden="true"
                      style={{ padding: '11px' }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye-slash"
                      aria-hidden="true"
                      style={{ padding: '11px' }}
                    ></i>
                  )}
                </span>
              </div>
              <div>
                {this.state.match === true ? (
                  <p style={{ fontSize: 'small' }}>Confirmed</p>
                ) : (
                  ''
                )}
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-success w-100"
              style={{ marginTop: '25px' }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}
