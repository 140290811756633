import React, { Component } from 'react';
import Upload from '../components/Upload';
import SideBar from '../components/SideBar';
import AddDetails from '../components/Forms/AddDetails';
import ThemeHeader from '../components/Header/ThemeHeader';

export default class UploadDicomImage extends Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: 'white',
          maxHeight: '100vh',
        }}
      >
        <div>
          <ThemeHeader />
          <div className="container-fluid">
            <div className="row">
              <SideBar />
              <div
                style={{
                  marginLeft: '230px',
                  minHeight: 'calc(100vh - 69px)',
                  marginTop: '69px',
                  padding: '7% 30%',
                }}
              >
                <Upload />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
