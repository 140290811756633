import React, { Component } from 'react';
import Modal from 'react-modal';
import { Redirect } from 'react-router-dom';
import './Modal.css';
import ReactTooltip from 'react-tooltip';

export default class AssignDoctorModal extends Component {
  constructor(props) {
    super();

    this.state = {
      modalIsOpen: false,
      interest: [],
      redirect: false,
      data: [],
      search: '',
      selecteduser: null,
      study: null,
      sharedstatus: '',
      shared: [],
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  // componentDidMount() {
  //   const url = 'http://localhost:8000/searchbyname';
  //   const search = {
  //     search: this.state.search,
  //   };
  //   fetch(url, {
  //     method: 'POST',
  //     body: JSON.stringify(search),
  //     headers: {
  //       'Content-Type': 'application/json',
  //       accesstoken: sessionStorage.getItem('token'),
  //     },
  //   })
  //     .then(res => res.json())
  //     .catch(err => alert(err))
  //     .then(res => {
  //       console.log(res);
  //       this.setState({ data: res.data, sharedstatus: res.data.sharedstatus });
  //     });

  //   fetch('http://localhost:8000/getsharedstatus', {
  //     method: 'POST',
  //     body: { studyInstanceUid: this.props.study.studyInstanceUid },
  //     headers: {
  //       'Content-Type': 'application/json',
  //       accesstoken: sessionStorage.getItem('token'),
  //     },
  //   })
  //     .then(res => res.json())
  //     .catch(err => alert(err))
  //     .then(res => {
  //       console.log(res);
  //       this.setState({ shared: res.data });
  //     });
  // }

  // componentWillMount() {
  //   this.setState({ items: this.state.data });
  // }

  openModal() {
    this.setState({ modalIsOpen: true });
    this.setState({ study: this.props.study });
    const url = 'https://pacs.space:5000/searchbyname';
    const search = {
      search: this.state.search,
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(search),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then((res) => res.json())
      .catch((err) => alert(err))
      .then((res) => {
        // console.log(res);
        this.setState({ data: res.data, sharedstatus: res.data.sharedstatus });
      });

    fetch('https://pacs.space:5000/getsharedstatus', {
      method: 'POST',
      body: JSON.stringify({
        studyInstanceUid: this.props.study.studyInstanceUid,
      }),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then((res) => res.json())
      .catch((err) => alert(err))
      .then((res) => {
        // console.log(res);
        this.setState({ shared: res.data });
      });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleAssign = (e) => {
    event.preventDefault();
    var finalobject = { ...this.state.selecteduser, ...this.state.study };
    const url = 'https://pacs.space:5000/adddoctorstudylist';
    const accessionNumber = finalobject.accessionNumber;
    const studyDescription = finalobject.studyDescription;
    const data = {
      fname: finalobject.fname,
      lname: finalobject.lname,
      email: finalobject.email,
      modalities: finalobject.modalities,
      accessionnumber: accessionNumber == undefined ? 'empty' : accessionNumber,
      numberofstudyrelatedinstances: finalobject.numberOfStudyRelatedInstances,
      numberofstudyrelatedseries: finalobject.numberOfStudyRelatedSeries,
      patientid: finalobject.patientId,
      patientname: finalobject.patientName,
      receivedon: finalobject.receivedon,
      studydate: finalobject.studyDate,
      studydescription:
        studyDescription == undefined ? 'empty' : studyDescription,
      studyinstanceuid: finalobject.studyInstanceUid,
      sharedstatus: 1,
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then((res) => res.json())
      .catch((err) => alert(err))
      .then((res) => {
        // console.log(res);
        this.setState({ modalIsOpen: false });
        alert('Assigned');
      });
  };

  handleChange = () => {
    var value = event.target.value;
    var name = event.target.name;
    this.setState({ [name]: value });
  };

  handleStatus = (studyInstanceUid, email) => {
    event.preventDefault();
    const url = 'https://pacs.space:5000/deleteshareddata';
    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        studyInstanceUid: studyInstanceUid,
        email: email,
      }),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then((res) => res.json())
      .catch((err) => alert(err))
      .then((res) => {
        // console.log(res);
        this.setState({ modalIsOpen: false });
        alert('Sharing is stopped');
      });
  };

  handleSelect = (d, e) => {
    var value = d;
    this.setState({ selecteduser: value }, () => {
      this.handleAssign(e);
    });
  };

  handleSearch = (event) => {
    event.preventDefault();
    const url = 'https://pacs.space:5000/searchbyname';
    const search = {
      search: this.state.search,
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(search),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then((res) => res.json())
      .catch((err) => alert(err))
      .then((res) => {
        // console.log(res);
        this.setState({ data: res.data });
      });
  };

  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        <ReactTooltip />
        <button
          onClick={this.openModal}
          style={{ marginTop: '10px', borderRadius: '5px' }}
          data-tip="Assign Study to doctor"
        >
          <i className="fa fa-user-md"></i>
        </button>
        <Modal
          className="mod2"
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          contentLabel="Example Modal"
        >
          <h3
            ref={(subtitle) => (this.subtitle = subtitle)}
            // style={{ minMargin: '0 0 80% 0' }}
          >
            {/* Are you sure you want to logout ? */}
          </h3>
          <div className="panel panel-default">
            <div className="panel-heading" style={{ fontSize: '25px' }}>
              Assign Doctor To Doctor
              <button
                className="btn btn-danger"
                onClick={this.closeModal}
                style={{ float: 'right', height: '35px' }}
              >
                Close
              </button>
            </div>
            <div className="panel-body">
              <form id="form_basic_validation" onSubmit={this.validation}>
                <div className="form-group">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="name"
                      placeholder="Search by doctor name"
                      onChange={this.handleChange}
                      name="search"
                    />
                    <span
                      className="input-group-addon"
                      style={{ width: '40px' }}
                      onClick={this.handleSearch}
                    >
                      <i
                        className="fa fa-search"
                        aria-hidden="true"
                        style={{ padding: '7px 12px' }}
                      ></i>
                    </span>
                  </div>
                </div>
                <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        {/* <th>Assign</th> */}
                        <th>Name</th>
                        <th>Email</th>
                        <th>Action</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data &&
                        this.state.data.map((d, index) => {
                          return (
                            <>
                              <tr className="fontcolor">
                                <td>{index + 1}</td>
                                {/* <td> */}
                                {/* <button onClick={() => this.handleSelect(d)}>
                                  {this.state.select === true
                                    ? 'selected'
                                    : 'select'}
                                </button> */}

                                {/* <input
                                    name="selecteduser"
                                    type="radio"
                                    // checked={check[studyInstanceUid]}
                                    onClick={() => this.handleSelect(d)}
                                  ></input> */}
                                {/* </td>  */}
                                <td>
                                  {d.fname} {d.lname}
                                </td>
                                <td>{d.email}</td>
                                <td>
                                  {this.state.shared &&
                                    this.state.shared.map((share, index) => {
                                      if (
                                        share.email == d.email &&
                                        share.sharedstatus == '1'
                                      ) {
                                        return (
                                          <button
                                            onClick={() =>
                                              this.handleStatus(
                                                this.state.study
                                                  .studyInstanceUid,
                                                d.email
                                              )
                                            }
                                          >
                                            Stop
                                          </button>
                                        );
                                      }
                                    })}
                                </td>
                                <td>
                                  {' '}
                                  <button onClick={() => this.handleSelect(d)}>
                                    Share
                                  </button>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
