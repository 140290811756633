import './StudyList.styl';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import TableSearchFilter from './TableSearchFilter.js';
import PropTypes from 'prop-types';
import { StudyListLoadingText } from './StudyListLoadingText.js';
import { useTranslation } from 'react-i18next';
import AssignDoctorModal from './Modal.js';
import ReactTooltip from 'react-tooltip';
// import AssignPatientModal from './PatientModal.js';

const getContentFromUseMediaValue = (
  displaySize,
  contentArrayMap,
  defaultContent
) => {
  const content =
    displaySize in contentArrayMap
      ? contentArrayMap[displaySize]
      : defaultContent;

  return content;
};
/**
 *
 *
 * @param {*} props
 * @returns
 */
function StudyList(props) {
  const {
    isLoading,
    hasError,
    studies,
    sort,
    onSort: handleSort,
    oncheck: handleCheck,
    download: handleDownload,
    onsharestudies: handleCheckStudytoShare,
    onallcheck: handleAllCheck,
    onallshare: handleAllSharedStudies,
    checkall,
    shareall,
    filterValues,
    onFilterChange: handleFilterChange,
    onSelectItem: handleSelectItem,
    studyListDateFilterNumDays,
    displaySize,
  } = props;
  const { t, ready: translationsAreReady } = useTranslation('StudyList');

  var largeTableMeta;
  if (
    'patient' === sessionStorage.getItem('type') ||
    'doctor' === sessionStorage.getItem('type')
  ) {
    largeTableMeta = [
      {
        displayText: t('PatientName'),
        fieldName: 'patientName',
        inputType: 'text',
        size: 250,
      },
      {
        displayText: t('MRN'),
        fieldName: 'patientId',
        inputType: 'text',
        size: 200,
      },
      {
        displayText: t('AccessionNumber'),
        fieldName: 'accessionNumber',
        inputType: 'text',
        size: 180,
      },
      {
        displayText: t('StudyDate'),
        fieldName: 'studyDate',
        inputType: 'date-range',
        size: 300,
      },
      {
        displayText: t('Modality'),
        inputType: 'text',
        fieldName: 'modalities',

        size: 114,
      },
      {
        displayText: t('StudyDescription'),
        fieldName: 'studyDescription',
        inputType: 'text',
        size: 200,
      },
      {
        displayText: t('Received On'),
        fieldName: 'ReceivedOn',
        inputType: 'text',
        size: 150,
      },

      {
        displayText: t('#Series'),
        fieldName: 'Series',
        inputType: 'text',
        size: 100,
      },
      {
        displayText: t('#Image'),
        fieldName: 'Images',
        inputType: 'text',
        size: 100,
      },
      {
        displayText: t('Action'),
        fieldName: 'Action',
        inputType: 'text',
        size: 100,
      },
    ];
  } else {
    largeTableMeta = [
      {
        displayText: t('Select'),
        fieldName: 'share/sendDicom',
        inputType: 'checkbox',
        onsharestudies: handleCheckStudytoShare,
        onallshare: handleAllSharedStudies,
        size: 70,
      },
      {
        displayText: t('PatientName'),
        fieldName: 'patientName',
        inputType: 'text',
        size: 250,
      },
      {
        displayText: t('MRN'),
        fieldName: 'patientId',
        inputType: 'text',
        size: 200,
      },
      {
        displayText: t('AccessionNumber'),
        fieldName: 'accessionNumber',
        inputType: 'text',
        size: 180,
      },
      {
        displayText: t('StudyDate'),
        fieldName: 'studyDate',
        inputType: 'date-range',
        size: 300,
      },
      {
        displayText: t('Modality'),
        inputType: 'text',
        fieldName: 'modalities',

        size: 114,
      },
      {
        displayText: t('StudyDescription'),
        fieldName: 'studyDescription',
        inputType: 'text',
        size: 200,
      },
      {
        displayText: t('Received On'),
        fieldName: 'ReceivedOn',
        inputType: 'text',
        size: 150,
      },

      {
        displayText: t('#Series'),
        fieldName: 'Series',
        inputType: 'text',
        size: 100,
      },
      {
        displayText: t('#Image'),
        fieldName: 'Images',
        inputType: 'text',
        size: 100,
      },
      {
        displayText: t('Action'),
        fieldName: 'Action',
        inputType: 'text',
        size: 100,
      },
    ];
  }
  var mediumTableMeta;
  if (
    'patient' === sessionStorage.getItem('type') ||
    'doctor' === sessionStorage.getItem('type')
  ) {
    mediumTableMeta = [
      {
        displayText: t('PatientName'),
        fieldName: 'patientName',
        inputType: 'text',
        size: 150,
      },
      {
        displayText: t('MRN'),
        fieldName: 'patientId',
        inputType: 'text',
        size: 150,
      },
      {
        displayText: t('AccessionNumber'),
        fieldName: 'accessionNumber',
        inputType: 'text',
        size: 180,
      },
      {
        displayText: t('StudyDate'),
        fieldName: 'studyDate',
        inputType: 'date-range',
        size: 350,
      },
      {
        displayText: t('Modality'),
        fieldName: 'modalities',
        inputType: 'text',
        size: 100,
      },
      {
        displayText: t('Received On'),
        fieldName: 'ReceivedOn',
        inputType: 'text',
        size: 150,
      },
      {
        displayText: t('StudyDescription'),
        fieldName: 'studyDescription',
        inputType: 'text',
        size: 180,
      },
      {
        displayText: t('#Series'),
        fieldName: 'Series',
        inputType: 'text',
        size: 100,
      },
      {
        displayText: t('#Images'),
        fieldName: 'Images',
        inputType: 'text',
        size: 100,
      },
      {
        displayText: t('Action'),
        fieldName: 'Action',
        inputType: 'text',
        size: 100,
      },
      // {
      //   displayText: `${t('PatientName')} / ${t('MRN')}`,
      //   fieldName: 'patientNameOrId',
      //   inputType: 'text',
      //   size: 250,
      // },
      // {
      //   displayText: t('Description'),
      //   fieldName: 'accessionOrModalityOrDescription',
      //   inputType: 'text',
      //   size: 150,
      // },
      // {
      //   displayText: t('StudyDate'),
      //   fieldName: 'studyDate',
      //   inputType: 'date-range',
      //   size: 300,
      // },
    ];
  } else {
    mediumTableMeta = [
      {
        displayText: t('Select'),
        fieldName: 'share/sendDicom',
        inputType: 'checkbox',
        onsharestudies: handleCheckStudytoShare,
        onallshare: handleAllSharedStudies,
        size: 70,
      },
      {
        displayText: t('PatientName'),
        fieldName: 'patientName',
        inputType: 'text',
        size: 230,
      },
      {
        displayText: t('MRN'),
        fieldName: 'patientId',
        inputType: 'text',
        size: 140,
      },
      {
        displayText: t('AccessionNumber'),
        fieldName: 'accessionNumber',
        inputType: 'text',
        size: 180,
      },
      {
        displayText: t('StudyDate'),
        fieldName: 'studyDate',
        inputType: 'date-range',
        size: 350,
      },
      {
        displayText: t('Modality'),
        fieldName: 'modalities',
        inputType: 'text',
        size: 100,
      },
      {
        displayText: t('Received On'),
        fieldName: 'ReceivedOn',
        inputType: 'text',
        size: 140,
      },
      {
        displayText: t('StudyDescription'),
        fieldName: 'studyDescription',
        inputType: 'text',
        size: 180,
      },
      {
        displayText: t('#Series'),
        fieldName: 'Series',
        inputType: 'text',
        size: 100,
      },
      {
        displayText: t('#Images'),
        fieldName: 'Images',
        inputType: 'text',
        size: 100,
      },
      {
        displayText: t('Action'),
        fieldName: 'Action',
        inputType: 'text',
        size: 130,
      },
    ];
  }

  const smallTableMeta = [
    {
      displayText: t('Search'),
      fieldName: 'allFields',
      inputType: 'text',
      size: 100,
    },
  ];

  const tableMeta = getContentFromUseMediaValue(
    displaySize,
    { large: largeTableMeta, medium: mediumTableMeta, small: smallTableMeta },
    smallTableMeta
  );

  const totalSize = tableMeta
    .map((field) => field.size)
    .reduce((prev, next) => prev + next);

  return translationsAreReady ? (
    <table className="table table--striped table--hoverable">
      <colgroup>
        {tableMeta.map((field, i) => {
          const size = field.size;
          const percentWidth = (size / totalSize) * 100.0;

          return <col key={i} style={{ width: `${percentWidth}%` }} />;
        })}
      </colgroup>
      <ReactTooltip />
      <thead className="table-head">
        <tr className="filters">
          <TableSearchFilter
            meta={tableMeta}
            values={filterValues}
            onSort={handleSort}
            onValueChange={handleFilterChange}
            sortFieldName={sort.fieldName}
            sortDirection={sort.direction}
            handleAllCheck={handleAllCheck}
            handleAllSharedStudies={handleAllSharedStudies}
            checkall={checkall}
            shareall={shareall}
            studyListDateFilterNumDays={studyListDateFilterNumDays}
          />
        </tr>
      </thead>
      <tbody className="table-body" data-cy="study-list-results">
        {/* I'm not in love with this approach, but it's the quickest way for now
         *
         * - Display different content based on loading, empty, results state
         *
         * This is not ideal because it create a jump in focus. For loading especially,
         * We should keep our current results visible while we load the new ones.
         */}
        {/* LOADING */}
        {isLoading && (
          <tr className="no-hover">
            <td colSpan={tableMeta.length}>
              <StudyListLoadingText />
            </td>
          </tr>
        )}
        {!isLoading && hasError && (
          <tr className="no-hover">
            <td colSpan={tableMeta.length}>
              <div className="notFound">
                {t('There was an error fetching studies')}
              </div>
            </td>
          </tr>
        )}
        {/* EMPTY */}
        {!isLoading && !studies.length && (
          <tr className="no-hover">
            <td colSpan={tableMeta.length}>
              <div className="notFound">{t('No matching results')}</div>
            </td>
          </tr>
        )}
        {/* <div className="container" style={{ width: '250px' }}>
          <div className="row">
            <div className="col-2">
              {' '}
              <input
                type="checkbox"
                style={{ width: '20px', margin: '1px' }}
                value={checkall}
                disabled={checkstatus2}
                onChange={e => {
                  handleAllCheck(checkall);
                }}
              />
            </div>
          </div>
        </div> */}
        {!isLoading &&
          studies.map((study, index) => (
            <TableRow
              key={`${study.studyInstanceUid}-${index}`}
              onClick={(studyInstanceUid) => handleSelectItem(studyInstanceUid)}
              AssignPatient={
                <input
                  style={{ width: '20px', marginRight: '24px' }}
                  name={study.studyInstanceUid}
                  type="checkbox"
                  value=""
                  checked={study.isshared}
                  // checked={check[studyInstanceUid]}
                  onChange={(e) => {
                    handleCheckStudytoShare(study.isshared, study);
                  }}
                />
              }
              // check={
              //   <input
              //     style={{ width: '20px', marginLeft: '16px' }}
              //     name={study.studyInstanceUid}
              //     type="checkbox"
              //     value={study.studyInstanceUid}
              //     checked={study.ischecked}
              //     // checked={check[studyInstanceUid]}
              //     onChange={(e) => {
              //       handleCheck(study.ischecked, e.target.value);
              //     }}

              // const target = event.target;
              // const value =
              //   target.name === { check } ? target.checked : target.value;
              // let checkVal = { ...checks };
              // checkVal[studyInstanceUid] = value;
              // console.log({ checks });
              // setcheck(checkVal);
              //   />
              // }
              accessionNumber={study.accessionNumber || ''}
              modalities={study.modalities}
              patientId={study.patientId || ''}
              patientName={study.patientName || ''}
              studyDate={study.studyDate}
              studyDescription={study.studyDescription || ''}
              numberofStudyRelatedSeries={
                study.numberOfStudyRelatedSeries || '1'
              }
              numberofStudyRelatedInstances={
                study.numberOfStudyRelatedInstances || '1'
              }
              studyInstanceUid={study.studyInstanceUid}
              receivedon={study.receivedon || ''}
              displaySize={displaySize}
              Action={
                <button
                  style={{ borderRadius: '5px' }}
                  data-tip="Click to view study"
                >
                  <i className="fa fa-eye"></i>
                </button>
              }
              Download={
                <button
                  style={{ borderRadius: '5px' }}
                  data-tip="Click to Download study"
                  onClick={() => handleDownload(study.studyInstanceUid)}
                >
                  <i className="fa fa-download"></i>
                </button>
              }
              AssignDoctor={study || ''}
            />
          ))}
      </tbody>
    </table>
  ) : null;
}

StudyList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  studies: PropTypes.array.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  // ~~ SORT
  sort: PropTypes.shape({
    fieldName: PropTypes.string,
    direction: PropTypes.oneOf(['desc', 'asc', null]),
  }).isRequired,
  onSort: PropTypes.func.isRequired,
  // ~~ FILTERS
  filterValues: PropTypes.shape({
    patientName: PropTypes.string.isRequired,
    patientId: PropTypes.string.isRequired,
    accessionNumber: PropTypes.string.isRequired,
    studyDate: PropTypes.string.isRequired,
    modalities: PropTypes.string.isRequired,
    studyDescription: PropTypes.string.isRequired,
    patientNameOrId: PropTypes.string.isRequired,
    accessionOrModalityOrDescription: PropTypes.string.isRequired,
    allFields: PropTypes.string.isRequired,
    numberOfStudyRelatedInstances: PropTypes.string.isRequired,
    numberOfStudyRelatedSeries: PropTypes.any,
    studyDateTo: PropTypes.any,
    studyDateFrom: PropTypes.any,
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  studyListDateFilterNumDays: PropTypes.number,
  displaySize: PropTypes.string,
};

StudyList.defaultProps = {};

// useEffect(() => {
//   fetch('http://127.0.0.1:8042/instances')
//     .then(res => res.json())
//     .catch(err => {
//       console.log(err);
//     })
//     .then(res => {
//       const arr = res;
//       if (arr) {
//         arr.map(data => {
//           fetch(`http://127.0.0.1:8042/instances/${data}/simplified-tags`)
//             .then(res => res.json())
//             .catch(err => {
//               console.log(err);
//             })
//             .then(res => {
//               if (
//                 res.StudyInstanceUID ===
//                 '1.2.840.113619.2.30.1.1762295590.1623.978668949.886'
//               ) {
//                 const newarr = data;
//                 console.log(newarr, 'hello');
//               }
//             });
//         });
//       }
//     });
// });

function TableRow(props) {
  // useEffect(() => {
  //   fetch('http://127.0.0.1:8042/instances')
  //     .then(res => res.json())
  //     .catch(err => {
  //       console.log(err);
  //     })
  //     .then(res => {
  //       const arr = res;
  //       if (arr) {
  //         arr.map(data => {
  //           fetch(`http://127.0.0.1:8042/instances/${data}/simplified-tags`)
  //             .then(res => res.json())
  //             .catch(err => {
  //               console.log(err);
  //             })
  //             .then(res => {
  //               if (
  //                 res.StudyInstanceUID ===
  //                 '1.2.840.113619.2.30.1.1762295590.1623.978668949.886'
  //               ) {
  //                 const newarr = data;
  //                 console.log(newarr, 'hello');
  //                 modality.map(() => {
  //                   fetch('http://localhost:8042/modalities/sample/store', {
  //                     method: 'POST',
  //                     mode: 'no-cors',
  //                     body: JSON.stringify(newarr),
  //                     headers: { 'Content-Type': 'application/json' },
  //                   })
  //                     .then(res => res.json())
  //                     .catch(error => console.log(error))
  //                     .then(res => {
  //                       console.log(res);
  //                     });
  //                 });
  //               }
  //             });
  //         });
  //       }
  //     });
  // });
  // fetch('http://localhost:8042/modalities/sample/store', {
  //   method: 'POST',
  //   mode: 'no-cors',
  //   body: JSON.stringify(newarr),
  //   headers: { 'Content-Type': 'application/json' },
  // })
  //   .then(res => res.json())
  //   .catch(error => console.log(error))
  //   .then(res => {
  //     console.log(res);
  //   });

  const {
    AssignPatient,
    AssignDoctor,
    Action,
    Download,
    numberofStudyRelatedSeries,
    numberofStudyRelatedInstances,
    accessionNumber,
    isHighlighted,
    modalities,
    patientId,
    patientName,
    studyDate,
    check,
    receivedon,
    studyDescription,
    studyInstanceUid,
    onClick: handleClick,
    displaySize,
  } = props;

  const { t } = useTranslation('StudyList');

  const largeRowTemplate = (
    <tr
      className={classNames({ active: isHighlighted })}
      onDoubleClick={() => handleClick(studyInstanceUid)}
    >
      {'patient' == sessionStorage.getItem('type') ||
      'doctor' == sessionStorage.getItem('type') ? (
        ''
      ) : (
        <td style={{ padding: '0px', margin: '0px' }}>{AssignPatient}</td>
      )}

      {/* <input
          name={check}
          type="checkbox"
          value={studyInstanceUid}
          // checked={check[studyInstanceUid]}
          onChange={event => {
            let checkedArray = checks;
            let selectedValue = event.target.value;

            if (event.target.checked === true) {
              checkedArray.push(selectedValue);
              console.log(checks);
              console.log(checkedArray);
              setcheck(checkedArray);
            } else {
              let valueIndex = checkedArray.indexOf(selectedValue);
              checkedArray.splice(valueIndex, 1);
              console.log(checkedArray);
              setcheck(checkedArray);
            } */}

      {/* // const target = event.target;
            // const value =
            //   target.name === { check } ? target.checked : target.value;
            // let checkVal = { ...checks };
            // checkVal[studyInstanceUid] = value;
            // console.log({ checks });
            // setcheck(checkVal);
          }} */}
      {/* /> */}

      <td
        style={{ padding: '18px' }}
        className={classNames({ 'empty-value': !patientName })}
      >
        {patientName || `(${t('Empty')})`}
      </td>
      <td style={{ padding: '18px' }}>{patientId}</td>
      <td style={{ padding: '18px' }}>{accessionNumber}</td>
      <td style={{ padding: '18px' }}>{studyDate}</td>
      <td
        className={classNames({ 'empty-value': !modalities })}
        style={{ padding: '18px' }}
      >
        {modalities || `(${t('Empty')})`}
      </td>
      <td style={{ padding: '18px' }}>{studyDescription}</td>
      <td style={{ padding: '18px' }}>{receivedon}</td>

      <td style={{ padding: '18px' }}>{numberofStudyRelatedSeries}</td>
      <td style={{ padding: '18px' }}>{numberofStudyRelatedInstances}</td>
      <td style={{ padding: '0px' }}>
        {/* <td onClick={() => handleClick(studyInstanceUid)}>{Action}</td> */}
        {'patient' === sessionStorage.getItem('type') ||
        'doctor' === sessionStorage.getItem('type') ? (
          <td
            style={{ border: 'none', borderRadius: '5px', padding: '18px 8px' }}
            onClick={() => handleClick(studyInstanceUid)}
          >
            {Action}
          </td>
        ) : (
          <>
            <td
              style={{
                border: 'none',
                borderRadius: '5px',
                padding: '18px 4px',
              }}
              onClick={() => handleClick(studyInstanceUid)}
            >
              {Action}
            </td>
            <td
              style={{
                border: 'none',
                borderRadius: '5px',
                padding: '18px 0px',
              }}
            >
              {Download}
            </td>
            <td style={{ border: 'none', padding: '8px 4px' }}>
              <AssignDoctorModal study={AssignDoctor} />
            </td>
            {/* <td style={{ border: 'none' }}>
              <AssignPatientModal study={AssignPatient} />
            </td> */}
          </>
        )}
      </td>
      {/* {'patient' == sessionStorage.getItem('type') ||
      'doctor' == sessionStorage.getItem('type') ? (
        ''
      ) : (
        <td style={{ padding: '0px', margin: '0px' }}>{AssignPatient}</td>
      )} */}
    </tr>
  );

  const mediumRowTemplate = (
    <tr
      className={classNames({ active: isHighlighted })}
      onDoubleClick={() => handleClick(studyInstanceUid)}
    >
      {'patient' == sessionStorage.getItem('type') ||
      'doctor' == sessionStorage.getItem('type') ? (
        ''
      ) : (
        <td style={{ padding: '0px', margin: '0px' }}>{AssignPatient}</td>
      )}

      {/* <input
          name={check}
          type="checkbox"
          value={studyInstanceUid}
          // checked={check[studyInstanceUid]}
          onChange={event => {
            let checkedArray = checks;
            let selectedValue = event.target.value;

            if (event.target.checked === true) {
              checkedArray.push(selectedValue);
              console.log(checks);
              console.log(checkedArray);
              setcheck(checkedArray);
            } else {
              let valueIndex = checkedArray.indexOf(selectedValue);
              checkedArray.splice(valueIndex, 1);
              console.log(checkedArray);
              setcheck(checkedArray);
            } */}

      {/* // const target = event.target;
            // const value =
            //   target.name === { check } ? target.checked : target.value;
            // let checkVal = { ...checks };
            // checkVal[studyInstanceUid] = value;
            // console.log({ checks });
            // setcheck(checkVal);
          }} */}
      {/* /> */}

      <td
        style={{ padding: '18px' }}
        className={classNames({ 'empty-value': !patientName })}
      >
        {patientName || `(${t('Empty')})`}
      </td>
      <td style={{ padding: '18px' }}>{patientId}</td>
      <td style={{ padding: '18px' }}>{accessionNumber}</td>
      <td style={{ padding: '18px' }}>{studyDate}</td>
      <td
        className={classNames({ 'empty-value': !modalities })}
        style={{ padding: '18px' }}
      >
        {modalities || `(${t('Empty')})`}
      </td>
      <td style={{ padding: '18px' }}>{receivedon}</td>
      <td style={{ padding: '18px' }}>{studyDescription}</td>

      <td style={{ padding: '18px' }}>{numberofStudyRelatedSeries}</td>
      <td style={{ padding: '18px' }}>{numberofStudyRelatedInstances}</td>
      <td style={{ padding: '0px' }}>
        {/* <td onClick={() => handleClick(studyInstanceUid)}>{Action}</td> */}
        {'patient' === sessionStorage.getItem('type') ||
        'doctor' === sessionStorage.getItem('type') ? (
          <td
            style={{ border: 'none', borderRadius: '5px', padding: '18px 8px' }}
            onClick={() => handleClick(studyInstanceUid)}
          >
            {Action}
          </td>
        ) : (
          <>
            <td
              style={{
                border: 'none',
                borderRadius: '5px',
                padding: '18px 4px',
              }}
              onClick={() => handleClick(studyInstanceUid)}
            >
              {Action}
            </td>
            <td
              style={{
                border: 'none',
                borderRadius: '5px',
                padding: '18px 0px',
              }}
            >
              {Download}
            </td>
            <td style={{ border: 'none', padding: '8px 4px' }}>
              <AssignDoctorModal study={AssignDoctor} />
            </td>
            {/* <td style={{ border: 'none' }}>
              <AssignPatientModal study={AssignPatient} />
            </td> */}
          </>
        )}
      </td>
      {/* {'patient' == sessionStorage.getItem('type') ||
      'doctor' == sessionStorage.getItem('type') ? (
        ''
      ) : (
        <td style={{ padding: '0px', margin: '0px' }}>{AssignPatient}</td>
      )} */}
    </tr>
  );

  const smallRowTemplate = (
    <tr
      onClick={() => handleClick(studyInstanceUid)}
      className={classNames({ active: isHighlighted })}
    >
      <td style={{ position: 'relative', overflow: 'hidden' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* NAME AND ID */}
          <div
            className={classNames({ 'empty-value': !patientName })}
            style={{ width: '150px', minWidth: '150px' }}
          >
            <div style={{ fontWeight: 500, paddingTop: '3px' }}>
              {patientName || `(${t('Empty')})`}
            </div>
            <div style={{ color: '#60656f' }}>{patientId}</div>
          </div>

          {/* DESCRIPTION */}
          <div
            className="hide-xs"
            style={{
              whiteSpace: 'pre-wrap',
              flexGrow: 1,
              paddingLeft: '35px',
            }}
          >
            {studyDescription}
          </div>

          {/* MODALITY & DATE */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '80px',
              width: '80px',
            }}
          >
            <div
              className={classNames({
                modalities: modalities,
                'empty-value': !modalities,
              })}
              aria-label={modalities}
              title={modalities}
            >
              {modalities || `(${t('Empty')})`}
            </div>
            <div>{studyDate}</div>
          </div>
        </div>
      </td>
    </tr>
  );

  const rowTemplate = getContentFromUseMediaValue(
    displaySize,
    {
      large: largeRowTemplate,
      medium: mediumRowTemplate,
      small: smallRowTemplate,
    },
    smallRowTemplate
  );

  return rowTemplate;
}

TableRow.propTypes = {
  accessionNumber: PropTypes.string.isRequired,
  isHighlighted: PropTypes.bool,
  modalities: PropTypes.string,
  patientId: PropTypes.string.isRequired,
  patientName: PropTypes.string.isRequired,
  studyDate: PropTypes.string.isRequired,
  studyDescription: PropTypes.string.isRequired,
  studyInstanceUid: PropTypes.string.isRequired,
  displaySize: PropTypes.string,
};

TableRow.defaultProps = {
  isHighlighted: false,
};

export { StudyList };
