import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SideBar from '../components/SideBar';
import ThemeHeader from '../components/Header/ThemeHeader';
import { Link, Redirect } from 'react-router-dom';

export default class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      name: '',
      redirect: 'false',
    };
  }

  componentDidMount() {
    console.log(this.props.location.state);
    this.setState({
      text: this.props.location.state.data,
      name: this.props.location.state.name,
    });
    console.log(this.state.name);
  }

  handleChange = value => {
    this.setState({ text: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    var data;
    var url;

    if (this.state.name === '/cookiepolicy') {
      url = 'https://pacs.space:5000/updatecookiepolicy';
      data = {
        cookiepolicy: this.state.text,
      };
    }
    if (this.state.name === '/privacypolicy') {
      url = 'https://pacs.space:5000/updateprivacypolicy';
      data = {
        privacypolicy: this.state.text,
      };
    }

    if (this.state.name === '/termsandconditions') {
      url = 'https://pacs.space:5000/updatetermsandconditions';
      data = {
        termsandconditions: this.state.text,
      };
    }

    fetch(url, {
      method: 'POST',

      body: JSON.stringify(data),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => res.json())
      .catch(error => alert(error))
      .then(res => this.setState({ redirect: true }));
  };

  render() {
    if (this.state.redirect === true) {
      return <Redirect to={this.state.name} />;
    }
    return (
      <div style={{ backgroundColor: 'white', maxHeight: '100vh' }}>
        <div>
          <ThemeHeader />
          <div className="container-fluid">
            <div className="row" style={{ backgroundColor: 'white' }}>
              <SideBar />

              <div
                className="col-9"
                style={{
                  marginLeft: '230px',
                  minHeight: 'calc(100vh - 69px)',
                  marginTop: '69px',
                  padding: '2% 15% 0 25%',
                }}
              >
                <ReactQuill
                  value={this.state.text}
                  onChange={this.handleChange}
                />

                <div className="form-group">
                  <span>
                    <button
                      onClick={this.handleSubmit}
                      type="submit"
                      className="btn btn-sm btn-success pull-right my-1"
                    >
                      Submit
                    </button>
                  </span>
                  <span>
                    <Link
                      to={{
                        pathname: '/cms',
                      }}
                    >
                      <button
                        type="submit"
                        className="btn btn-sm btn-success pull-right mx-1 my-1"
                      >
                        Cancel
                      </button>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
