import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();
const options = [
  { value: '/doctors', label: 'Doctor' },
  { value: '/cms', label: 'Settings' },
  // { value: '/studylist', label: 'Study List' },
  { value: '/upload_dicom_image', label: 'Upload Exam' },
  { value: '/subadmin_listing', label: 'Power User' },
];

export default class EditSubUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      authfailed: false,
    };
  }

  componentDidMount() {
    this.setState({ data: this.props.data });
    this.setState({
      selectedoption: JSON.parse(this.props.data.selectedoption),
    });
  }

  apicall = () => {
    event.preventDefault();
    const url = 'https://pacs.space:5000/editsubuser';
    // const url = 'http://159.89.173.21:8000/addDetails';
    const data = {
      id: this.state.data.id,
      lname: this.state.data.lname.trim(),
      fname: this.state.data.fname.trim(),
      // email: this.state.data.email,
      selectedoption: JSON.stringify(this.state.selectedoption),
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then((res) => res.json())
      .catch((error) => alert('Something went wrong try again'))
      .then((res) => {
        if (res.message === 'Auth Failed') {
          this.setState({ authfailed: true });
        } else {
          if (res.success === true) {
            alert('Succesfully added');
            this.setState({ redirect: true });
          }
          if (res.success === false) {
            alert(res.message);
          }
        }
      });
  };

  validation = () => {
    event.preventDefault();
    alert('hello');
    const { fname, lname } = this.state;
    if (fname.trim() === '') {
      alert('First Name cannot be empty');
    } else if (lname.trim() === '') {
      alert('Last Name cannot be empty');
    } else {
      this.apicall();
    }
  };
  handle = (selectedoption) => {
    console.log(selectedoption);
    this.setState({ selectedoption });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    let newArr = {
      ...this.state.data,

      [name]: value,
    };
    this.setState({ data: newArr });
  };

  render() {
    if (this.state.authfailed && this.state.authfailed === true) {
      return (
        <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
          UnAuthorised Access
        </div>
      );
    } else {
      if (this.state.redirect === true) {
        return <Redirect to="/subadmin_listing" />;
      }
      return (
        <div className="panel panel-default">
          <div className="panel-heading" style={{ fontSize: '20px' }}>
            Edit Power User
          </div>
          <div className="panel-body">
            <form id="form_basic_validation" onSubmit={this.apicall}>
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="fname"
                  value={this.state.data && this.state.data.fname}
                  placeholder="First Name"
                  onChange={this.handleChange}
                  name="fname"
                  required
                />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="lname"
                  placeholder="Last Name"
                  value={this.state.data && this.state.data.lname}
                  onChange={this.handleChange}
                  name="lname"
                  required
                />
              </div>
              <div className="form-group">
                <label>Email Address</label>
                <input
                  type="email"
                  className="form-control form-control-sm"
                  id="email"
                  value={this.state.data && this.state.data.email}
                  placeholder="Email"
                  onChange={this.handleChange}
                  name="email"
                  disabled
                />
              </div>
              <div className="form-group">
                <label>Access to SubAdmin</label>

                <Select
                  onChange={this.handle}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  value={this.state.selectedoption && this.state.selectedoption}
                  options={options}
                  isMulti
                />
              </div>
              <div className="form-group">
                <span>
                  <button
                    type="submit"
                    className="btn btn-sm btn-success pull-right"
                  >
                    Submit
                  </button>
                </span>
                <span>
                  <Link
                    to={{
                      pathname: '/subadmin_listing',
                    }}
                  >
                    <button
                      type="submit"
                      className="btn btn-sm btn-success pull-right mx-1"
                    >
                      Cancel
                    </button>
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }
  }
}
