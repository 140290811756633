import React, { Component } from 'react';
import SideBar from '../components/SideBar';
import ThemeHeader from '../components/Header/ThemeHeader';
import { Link } from 'react-router-dom';
import './table.css';

export default class ModalityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    const url = 'https://pacs.space:5000/getmodalitydetails';
    // const url = 'http://159.89.173.21:8000/getsubadminlist';
    fetch(url, {
      method: 'POST',
      headers: {
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then(response => response.json())
      .catch(error => alert(error))
      .then(response => {
        console.log(response.data);
        this.setState({ data: response.data });
      });
  }

  //   handleStatus = (id, status) => {
  //     var index = event.target.getAttribute('data-key');
  //     // const url = 'http://localhost:8000/updatesubadminstatus';
  //     const url = 'http://159.89.173.21:8000/updatesubadminstatus';

  //     const data = {
  //       id: id,
  //       status: status,
  //     };
  //     fetch(url, {
  //       method: 'POST',
  //       body: JSON.stringify(data),
  //       headers: { 'Content-Type': 'application/json' },
  //     })
  //       .then(res => {
  //         console.log(this.state.data[index]);
  //         const { data } = this.state;
  //         var s = status === 0 ? 1 : 0;
  //         data[index].status = s;
  //         this.setState({
  //           data,
  //         });
  //       })
  //       .catch(error => alert(error));
  //   };
  render() {
    return (
      <div style={{ backgroundColor: 'white', maxHeight: '100vh' }}>
        <ThemeHeader />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
          </div>
          <div
            style={{
              marginLeft: '230px',
              minHeight: 'calc(100vh - 69px)',
              marginTop: '69px',
              padding: '2% 2% 0 2%',
            }}
          >
            <div>
              {/* <h1>Coming Soon</h1> */}
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div style={{ fontSize: '30px' }}>
                  <span>Modality List</span>
                  <span>
                    <Link
                      to={{
                        pathname: '/add_modality',
                      }}
                    >
                      <button
                        style={{
                          fontSize: '20px',
                          float: 'right',
                          margin: '2px',
                        }}
                      >
                        Add modality
                      </button>
                    </Link>
                  </span>
                </div>
                <div className="panel-body">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>dicomserver</th>
                        <th>Aet</th>
                        <th>ip address</th>
                        <th>port</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data &&
                        this.state.data.map((data, index) => {
                          return (
                            <tr key={data.id} className="fontcolor">
                              <td>{index + 1}</td>
                              <td>{data.ds}</td>
                              <td>{data.aet}</td>
                              <td>{data.ipaddress}</td>
                              <td>{data.port}</td>
                              <td>
                                <span>
                                  <Link
                                    to={{
                                      state: {
                                        data: data,
                                      },
                                      pathname: '/edit_modality',
                                    }}
                                  >
                                    <button>Edit</button>
                                  </Link>
                                </span>
                                {/* <span>
                                  <button
                                    style={{ minWidth: '38%' }}
                                    data-key={index}
                                    onClick={() =>
                                      this.handleStatus(data.id, data.status)
                                    }
                                  >
                                    {data.status === 0
                                      ? 'Activate'
                                      : 'Deactivate'}
                                  </button>
                                </span> */}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
