import React, { Component } from 'react';

import { Redirect, Link } from 'react-router-dom';

export default class AddModalityDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      dicomserver: null,
      aet: null,
      ipaddress: null,
      ip1: '',
      ip2: '',
      ip3: '',
      ip4: '',
      redirect: false,
      port: '',
      authfailed: false,
    };
  }

  onChange = date => this.setState({ date });

  apicall = () => {
    // const url = 'http://159.89.173.21:8000/addsubuser';
    const url = 'https://pacs.space:5000/addmodality';
    const { ip1, ip2, ip3, ip4, aet, dicomserver, port } = this.state;
    const data = {
      dicomserver: dicomserver.trim(),
      aet: aet.trim(),
      ipaddress: `${ip1}.${ip2}.${ip3}.${ip4}`,
      port: port,
    };
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        accesstoken: sessionStorage.getItem('token'),
      },
    })
      .then(res => res.json())
      .catch(error => alert('Something went wrong try again'))
      .then(res => {
        if (res.message === 'Auth Failed') {
          this.setState({ authfailed: true });
        } else {
          if (res.success === true) {
            alert('Succesfully added');
            this.setState({ redirect: true });
          }
          if (res.success === false) {
            alert(res.message);
          }
        }
      });
  };

  validation = () => {
    event.preventDefault();
    const { aet, dicomserver } = this.state;
    if (aet.trim() === '') {
      alert('aet cannot be empty');
    } else if (dicomserver.trim() === '') {
      alert('dicomserver cannot be empty');
    } else {
      this.apicall();
    }
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({ [name]: value });
  };

  render() {
    if (this.state.authfailed && this.state.authfailed === true) {
      return (
        <div style={{ backgroundColor: 'white', minHeight: '100vh' }}>
          UnAuthorised Access
        </div>
      );
    } else {
      if (this.state.redirect === true) {
        return <Redirect to="/cms" />;
      }
      return (
        <div className="panel panel-default">
          <div className="panel-heading" style={{ fontSize: '20px' }}>
            Add Modality
          </div>
          <div className="panel-body" style={{ paddingBottom: '15%' }}>
            <form id="form-horizontal" onSubmit={this.validation}>
              <div className="form-group">
                <label>Dicom Server</label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="dicomserver"
                  placeholder=""
                  onChange={this.handleChange}
                  name="dicomserver"
                  required
                />
              </div>
              <div className="form-group">
                <label>AET </label>
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="aet"
                  placeholder=""
                  onChange={this.handleChange}
                  name="aet"
                  required
                />
              </div>
              <div className="form-group">
                <label>IP Address</label>
                <div className="form-group row">
                  <div className="col-sm-3">
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      id="ip1"
                      placeholder=""
                      onChange={this.handleChange}
                      name="ip1"
                      maxLength="3"
                      required
                    />
                  </div>
                  <div className="col-sm-3">
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      id="ip2"
                      placeholder=""
                      onChange={this.handleChange}
                      name="ip2"
                      max="999"
                      required
                    />
                  </div>
                  <div className="col-sm-3">
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      id="ip3"
                      placeholder=""
                      onChange={this.handleChange}
                      name="ip3"
                      max="999"
                      required
                    />
                  </div>
                  <div className="col-sm-3">
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      id="ip4"
                      placeholder=""
                      onChange={this.handleChange}
                      name="ip4"
                      max="999"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label> Port</label>
                  <input
                    type="number"
                    className="form-control form-control-sm"
                    id="port"
                    placeholder=""
                    onChange={this.handleChange}
                    name="port"
                    max="9999"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <span>
                  <button
                    type="submit"
                    className="btn btn-sm btn-success pull-right"
                  >
                    Submit
                  </button>
                </span>
                <span>
                  <Link
                    to={{
                      pathname: '/cms',
                    }}
                  >
                    <button
                      type="submit"
                      className="btn btn-sm btn-success pull-right mx-1"
                    >
                      Cancel
                    </button>
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      );
    }
  }
}
